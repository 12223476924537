import {transitions} from '@/products/Aside'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import VillaIcon from '@mui/icons-material/Villa'
import {Box, BoxProps, Typography} from '@mui/material'
import {ChangeEvent, useCallback, useEffect, useMemo} from 'react'
import {AutocompleteArrayInput, Form, ReferenceArrayInput, useListFilterContext} from 'react-admin'

interface FilterCommune extends BoxProps {
    active: boolean;
}

export default function FilterCommune(props: FilterCommune) {

    const {active, onClick} = props

    const PARAMS = useMemo(() => ({
        label: 'Communes',
        icon: <VillaIcon color="action"/>
    }), [])

    const {filterValues, setFilters} = useListFilterContext()

    const handleTagsChange = useCallback((event: ChangeEvent) => {
        setFilters({
            ...filterValues,
            communes: event
            // communes_operator: filterValues?.operator ?? PARAMS.operator
        }, true)
    }, [])

    // const handleOperatorChange = useCallback((event: any) => {
    //     setFilters({
    //         ...filterValues
    //         communes_operator: event.target.value
    //     }, true)
    // }, [])

    useEffect(() => {
        return () => {
            setFilters({
                communes: []
                // communes_operator: PARAMS.operator
            }, true)
        }
    }, [])

    return (
        <Form>
            <Box>
                <Box display="flex" alignItems="center" onClick={onClick}>
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>{PARAMS.icon}</Box>
                        <Typography variant="overline">
                            {PARAMS.label}
                        </Typography>
                    </Box>
                    {active ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                </Box>
                <Box sx={{...transitions(active, '0.4s')}}>
                    <ReferenceArrayInput
                        name="communes"
                        label="Communes"
                        reference="communes"
                        source="communes"
                    >
                        <AutocompleteArrayInput
                            blurOnSelect={false}
                            name="communes"
                            optionText="name"
                            multiple={true}
                            optionValue="id"
                            debounce={250}
                            onChange={handleTagsChange}
                            size="medium"
                            defaultValue={filterValues?.tags}
                        />
                    </ReferenceArrayInput>
                    {/*<SelectInput*/}
                    {/*    source="operator"*/}
                    {/*    className="selectInput"*/}
                    {/*    label="Opérateur"*/}
                    {/*    optionValue="name"*/}
                    {/*    choices={[*/}
                    {/*        {id: 'or', name: 'OR'},*/}
                    {/*        {id: 'and', name: 'AND'}*/}
                    {/*    ]}*/}
                    {/*    defaultValue={PARAMS.operator}*/}
                    {/*    validate={required()}*/}
                    {/*    onChange={handleOperatorChange}*/}
                    {/*/>*/}
                    {/*<FormControl variant="filled">*/}
                    {/*    <InputLabel id="operator">Opérateur</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        labelId="operator"*/}
                    {/*        id="filter-commune-operator"*/}
                    {/*        value={filterValues.operator}*/}
                    {/*        onChange={handleOperatorChange}*/}
                    {/*        defaultValue={'and'}*/}
                    {/*    >*/}
                    {/*        <MenuItem value={'or'}>ou</MenuItem>*/}
                    {/*        <MenuItem value={'and'}>et</MenuItem>*/}
                    {/*    </Select>*/}
                    {/*</FormControl>*/}
                </Box>
            </Box>
        </Form>
    )
};
