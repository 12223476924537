import {Producer, ProducerStore} from '@/types'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {
    AutocompleteInput,
    Link,
    ReferenceInput,
    required,
    TextInput,
    useCreatePath,
    useRecordContext
} from 'react-admin'

export default function FieldsPresentation() {

    const record = useRecordContext<ProducerStore>()
    const createPath = useCreatePath()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Présentation</Typography>
            <TextInput
                label="Nom"
                name="name"
                source="name"
                fullWidth
                validate={[required()]}
            />
            <ReferenceInput
                name="producer"
                source="producer"
                reference="producers"
            >
                <AutocompleteInput
                    label="Entreprise"
                    name="producer"
                    blurOnSelect={false}
                    optionText={(option: Producer) => `${option.name_enterprise} -- ${option.name}`}
                    optionValue="id"
                    debounce={100}
                    validate={[required()]}
                    helperText={
                        <>
                            {record?.producer && <Link
                                to={createPath({
                                    resource: 'producers',
                                    type: 'edit',
                                    id: record?.producer
                                })}
                            >
                                Modifier la fiche de l'entreprise
                            </Link>}
                        </>
                    }
                />
            </ReferenceInput>
        </Box>
    )
}
