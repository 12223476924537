import {Terroir} from '@/types'
import {Card, CardContent, Stack, Typography} from '@mui/material'
import React, {useCallback, useRef, useState} from 'react'
import {useCreatePath} from 'react-admin'
import {Link} from 'react-router-dom'
import Map, {Layer, MapboxEvent, MapRef, Source} from 'react-map-gl'
import {
	MAP_DATASET,
	MAP_LABEL_DATASET,
	MAP_LAYER_FILL,
	MAP_LAYER_LABELS,
	MAP_LAYER_LINE,
	MAP_STYLE
} from '@/lib/map/map'
import {getTerroirLink} from '@/lib/link'

export default function TerroirCardInfo({terroir}: { terroir: Terroir }) {

	const createPath = useCreatePath()

	const mapRef = useRef<MapRef | null>(null)

	const [viewState, setViewState] = useState({
		longitude: terroir.lng,
		latitude: terroir.lat,
		zoom: 8
	})

	const onLoad = useCallback((event: MapboxEvent) => {
		console.log(event)
		if (mapRef.current?.getMap().getSource(MAP_DATASET.sourceId)) {
			mapRef.current?.setFeatureState({
				source: MAP_DATASET.sourceId,
				sourceLayer: MAP_DATASET.sourceName,
				id: terroir.code
			}, {selected: true})
		}

		if (mapRef.current?.getMap().getSource(MAP_LABEL_DATASET.sourceId)) {
			mapRef.current?.setFeatureState({
				source: MAP_LABEL_DATASET.sourceId,
				sourceLayer: 'terroirs-center-polylabel',
				id: terroir.code
			}, {selected: true})
		}

	}, [terroir])

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">
					Terroir {terroir.article} {terroir.name}
				</Typography>

				<Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} justifyItems={'flex-start'} spacing={2}>
					<Link
						to={createPath({resource: 'terroirs', type: 'edit', id: terroir.id})}
					>
						Éditer le terroir
					</Link>
					<Link to={getTerroirLink(terroir)} target={'_blank'}>Voir sur
						lopt.org</Link>
				</Stack>
			</CardContent>
			<Map
				ref={mapRef}
				initialViewState={viewState}
				style={{width: '100%', height: 400}}
				// mapStyle="mapbox://styles/mapbox/streets-v9"
				mapStyle={MAP_STYLE['simple']}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX}
				interactiveLayerIds={['terroirs-fill']}
				onLoad={onLoad}
			>
				{/*<Geocoder*/}
				{/*    mapRef={mapRef}*/}
				{/*    onViewportChange={handleGeocoderViewportChange}*/}
				{/*    mapboxApiAccessToken={MAPBOX_TOKEN}*/}
				{/*    position="top-left"*/}
				{/*/>*/}
				{/*<Marker*/}
				{/*	longitude={terroir.lng}*/}
				{/*	latitude={terroir.lat}*/}
				{/*	offset={[0, 50]}*/}
				{/*/>*/}
				<Source
					id="terroirs"
					type="vector"
					url={MAP_DATASET.url}
					promoteId={MAP_DATASET.propertyId}
				>
					<Layer
						{...MAP_LAYER_FILL}
					/>
					<Layer
						{...MAP_LAYER_LINE}
					/>
				</Source>
				<Source
					id={MAP_LABEL_DATASET.sourceId}
					type="vector"
					url={MAP_LABEL_DATASET.url}
					promoteId={MAP_DATASET.propertyId}
				>
					<Layer
						{...MAP_LAYER_LABELS}
					/>
				</Source>
			</Map>
		</Card>
	)
}
