import DatagridStores from '@/producers/stores/components/listFields'
import {Alert, AlertTitle} from '@mui/material'
import * as React from 'react'
import {Fragment, useEffect} from 'react'
import {List, Pagination, SearchInput} from 'react-admin'

export default function ProducerStoreList() {
    return (
        <>
            <Alert severity="warning" style={{width: '100%', marginTop: 20}}>
                <AlertTitle>Attention à la hiérarchie des données !</AlertTitle>
                Les établissements <strong>sont les producteurs affichés sur la carte</strong> ; Ils doivent être reliés
                à une entreprise et
                plusieurs établissement (magasin, point de vente) peuvent donc appartenir à une même entreprise.
            </Alert>
            <List
                sort={{field: 'id', order: 'ASC'}}
                perPage={25}
                filters={[<SearchInput name="q" source="q" alwaysOn/>]}
                exporter={false}
            >
                <TableList/>
            </List>
        </>
    )
}

const TableList = () => {

    useEffect((): any => {
        document.title = 'Établissements | Producteurs'
    }, [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <DatagridStores
                optimized
                rowClick="edit"
            />
        </Fragment>
    )
}
