import React, {useCallback} from 'react'
import {useCreatePath} from 'react-admin'
import {Link} from 'react-router-dom'

interface Props {
    resource: string,
    label: string,
    id: number
}

export default function LabelLinked(props: Props) {
    const createPath = useCreatePath()
    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

    return (
        <Link
            to={createPath({resource: props.resource, type: 'edit', id: props.id})}
            onClick={handleClick}
            style={{textDecoration: 'none'}}
        >
            {props.label}
        </Link>
    )
}
