import {ProducerStore} from '@/types'
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {RaRecord, useDataProvider, useNotify, useRecordContext, useRefresh} from 'react-admin'

interface LinkStoreInterface {
    resourceToUpdate: string
}

export default function LinkStoreDialog<T extends RaRecord>({resourceToUpdate}: LinkStoreInterface) {

    const [open, setOpen] = useState(false)
    const [value, setValue] = useState<ProducerStore | null>(null)
    const record = useRecordContext<T>()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const handleSave = async () => {
        if (value) {
            await dataProvider.update(resourceToUpdate, {
                id: record.id,
                data: {
                    ...record,
                    ...{
                        stores: [
                            ...record.stores,
                            value.id
                        ]
                    }
                },
                previousData: record
            })
            refresh()
            notify('Établissement ajouté', {type: 'success'})
        }
        handleClose()
    }

    const [stores, setStores] = useState<ProducerStore[]>([])
    const [inputValue, setInputValue] = useState('')

    const updateOptions = useCallback((inputValue: string) => {
        dataProvider.getList('producers/stores', {
            filter: {
                q: inputValue
            },
            sort: {field: 'id', order: 'DESC'},
            pagination: {page: 1, perPage: 25}
        })
            .then(({data}) => {
                setStores(data)
            })
    }, [inputValue])

    useEffect(() => {
        updateOptions(inputValue)
    }, [inputValue])

    useEffect(() => {
        updateOptions('')
    }, [])

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Lier un établissement
            </Button>

            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle>Lier un établissement</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={stores}
                        noOptionsText={'Aucun résultat'}
                        getOptionLabel={(option) => `${option.name} -- ${option.commune_zipcode}`}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        filterOptions={(options) => options.filter(option => {
                            return !record.stores.includes(option.id)
                        })}
                        onChange={(event, newValue) => {
                            setValue(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Sélectionnez un établissement" fullWidth/>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};
