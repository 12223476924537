import { useState, useEffect } from 'react'
//import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin'

import Box from '@mui/material/Box'

const Login = () => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const translate = useTranslate()
    const login = useLogin()
    const notify = useNotify()
    //const location = useLocation()

    // ###################
    // TITRE DE LA PAGE
    useEffect((): any => {
        document.title = "Connexion"
    }, [])

    const handleSubmit = () => {
        setLoading(true)
        login({ email, password })
            .catch((data) => {
                setLoading(false)
                if (data.message) {
                    notify(data.message)
                } else {
                    notify('Une erreur est survenue')
                }
            })
    }

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    bgcolor: 'black',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>
                            <LockIcon />
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    ></Box>
                    <Box sx={{ padding: '0 1em 1em 1em' }}>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                autoFocus
                                source="email"
                                label={"Email"}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                source="password"
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                validate={required()}
                                fullWidth
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    )
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
}

export default Login

interface FormValues {
    email?: string
    password?: string
}
