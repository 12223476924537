import FieldsContact from '@/producers/stores/components/fields/fieldsContact'
import FieldsInfos from '@/producers/stores/components/fields/fieldsInfos'
import FieldsLocation from '@/producers/stores/components/fields/fieldsLocation'
import FieldsPresentation from '@/producers/stores/components/fields/fieldsPresentation'
import FieldsPublication from '@/producers/stores/components/fields/fieldsPublication'
import FieldsSell from '@/producers/stores/components/fields/fieldsSell'
import {Alert, Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {Create, DeleteButton, SaveButton, SimpleForm, Toolbar, useNotify, useRedirect} from 'react-admin'

export default function ProducerStoreCreate() {

    useEffect(() => {
        document.title = 'Ajouter un établissement'
    }, [])

    return (
        <Create
            title={'Ajouter un établissement'}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                noValidate
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Typography variant="h4" gutterBottom>Création d'un établissement</Typography>
                <Alert severity="info" style={{width: '100%', marginBottom: 20}}>Une
                    fiche entreprise <strong>doit déjà exister</strong> pour lier l'établissement à celle-ci</Alert>
                {/*<Typography gutterBottom>{record?.name_enterprise}</Typography>*/}
                <Grid
                    container
                    spacing={5}
                >
                    <Grid item xs={12} md={6} display={'flex'} flexDirection={'column'} gap={3}>
                        <FieldsPublication/>
                        <FieldsPresentation/>
                        <FieldsSell/>
                        <FieldsContact/>
                        <FieldsInfos/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldsLocation/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

const FormToolbar = () => {

    const notify = useNotify()
    const redirect = useRedirect()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton
                    className="save"
                    label="Enregistrer"
                    mutationOptions={{
                        onSuccess: event => {
                            // @todo redirect liste
                            notify('ra.notification.created', {
                                type: 'info',
                                messageArgs: {smart_count: 1}
                            })
                            // redirect('/users/admins', 'products')
                        }
                    }}
                />
            </Box>
            <DeleteButton/>
        </Toolbar>
    )
}
