import ProducerStoreEdit from '@/producers/stores/edit'
import ProducerStoreList from '@/producers/stores/list'
import ProducerStoreCreate from '@/producers/stores/create'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'

export default {
    icon: StoreOutlinedIcon,
    edit: ProducerStoreEdit,
    list: ProducerStoreList,
    create: ProducerStoreCreate
}
