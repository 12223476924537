import * as React from 'react'
import Button from '@mui/material/Button'

const Logo = () => {
    return (
        <Button sx={{transition: 'color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'}} href="/">L'observatoire des produits
            du terroir</Button>
    )
}

export default Logo
