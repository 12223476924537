import ProducerEdit from '@/producers/edit'
import ProducerList from '@/producers/list'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import ProducerCreate from '@/producers/create'

export default {
    icon: LocationCityIcon,
    list: ProducerList,
    edit: ProducerEdit,
    create: ProducerCreate
}
