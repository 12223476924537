import React from 'react'
import { useRecordContext } from 'react-admin'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { Typography, Box } from '@mui/material';
import get from 'lodash/get';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress color="secondary" variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

const LinearProgressField = (props: any) => {
	const { source } = props
	const record = useRecordContext(props)
	const value = get(record, source)
	return <LinearProgressWithLabel value={value} />
}

export default LinearProgressField
