import {Pagination, ReferenceManyField, useCreatePath, useEditController} from 'react-admin'
import {AppContributionProducerStore} from '@/types'
import {Alert, AlertTitle, Card, CardContent, CardHeader, Chip, Grid, Stack, Typography} from '@mui/material'
import React, {useRef, useState} from 'react'
import Map, {Layer, MapRef, Marker, Source} from 'react-map-gl'
import DatagridProducers from '@/producers/components/datagrid'
import {Link} from 'react-router-dom'
import {
	MAP_DATASET,
	MAP_LABEL_DATASET,
	MAP_LAYER_FILL,
	MAP_LAYER_LABELS,
	MAP_LAYER_LINE,
	MAP_STYLE
} from '@/lib/map/map'
import {getProducerStoreLink} from '@/lib/link'

export default function EditProducerStoreContribution() {

	const {record, isLoading: loading} = useEditController<AppContributionProducerStore>()
	const createPath = useCreatePath()

	const mapRef = useRef<MapRef | null>(null)

	const [viewState, setViewState] = useState({
		zoom: 8,
		...(record && {
			longitude: record.store.coordinates.lng,
			latitude: record.store.coordinates.lat
		})
	})

	return record ? (
		<>
			<Grid item xs={12} md={6}>
				<Card sx={{height: '100%'}}>
					<CardHeader title={'Fiche Producteur'}/>
					<CardContent>
						<Stack gap={3}>
							<div>
								<Typography variant="h6" gutterBottom>{record.store.name}</Typography>
								{/*<Typography variant="subtitle1" gutterBottom>{record.product.categoryName}</Typography>*/}
								<Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} justifyItems={'flex-start'} spacing={2}>
									<Link
										to={createPath({
											resource: 'producers/stores',
											type: 'edit',
											id: record.store.id
										})}
									>Voir l'établissement</Link>
									<Link to={getProducerStoreLink(record.store)} target={'_blank'}>Voir sur
										lopt.org</Link>
								</Stack>
							</div>
							{/*<Alert severity="info" style={{width: '100%', marginTop: 20}}>*/}
							{/*	<AlertTitle>Traitement partiel automatique</AlertTitle>*/}
							{/*	Les valeurs d'héritage et d'existance ont été <strong>automatiquement*/}
							{/*	appliquées</strong> à l'indice de confiance du produit pour le*/}
							{/*	terroir {record.terroir.name_prefixed}.*/}
							{/*</Alert>*/}
							{/*<div>*/}
							{/*	<Typography variant="subtitle2" gutterBottom>Fait-il parti du*/}
							{/*		patrimoine {record.terroir.name_prefixed} ?</Typography>*/}
							{/*	<Chip*/}
							{/*		label={record.is_exist ? 'Oui' : 'Non'}*/}
							{/*		color={record.is_exist ? 'success' : 'error'}*/}
							{/*	/>*/}
							{/*</div>*/}
							<div>
								<Typography variant="subtitle2" gutterBottom>Est-il toujours ouvert ?</Typography>
								<Chip
									label={record.is_open ? 'Oui' : 'Non'}
									color={record.is_open ? 'success' : 'error'}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Pratique la vente à distance</Typography>
								<Chip
									label={record.is_vad ? 'Oui' : 'Non'}
									color={record.is_vad ? 'success' : 'error'}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Pratique la vente sur place</Typography>
								<Chip
									label={record.is_direct_selling ? 'Oui' : 'Non'}
									color={record.is_direct_selling ? 'success' : 'error'}
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Informations complémentaires</Typography>
								<Typography variant="body1">{record.description || 'N/A'}</Typography>

							</div>
						</Stack>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6}>
				<Card sx={{height: '100%'}}>
					<Map
						ref={mapRef}
						initialViewState={viewState}
						style={{width: '100%', height: '100%'}}
						// mapStyle="mapbox://styles/mapbox/streets-v9"
						mapStyle={MAP_STYLE['simple']}
						mapboxAccessToken={process.env.REACT_APP_MAPBOX}
						interactiveLayerIds={['terroirs-fill']}
						// onLoad={onLoad}
					>
						{/*<Geocoder*/}
						{/*    mapRef={mapRef}*/}
						{/*    onViewportChange={handleGeocoderViewportChange}*/}
						{/*    mapboxApiAccessToken={MAPBOX_TOKEN}*/}
						{/*    position="top-left"*/}
						{/*/>*/}
						<Marker
							longitude={record.store.coordinates.lng}
							latitude={record.store.coordinates.lat}
						/>
						<Source
							id="terroirs"
							type="vector"
							url={MAP_DATASET.url}
							promoteId={MAP_DATASET.propertyId}
						>
							<Layer
								{...MAP_LAYER_FILL}
							/>
							<Layer
								{...MAP_LAYER_LINE}
							/>
						</Source>
						<Source
							id={MAP_LABEL_DATASET.sourceId}
							type="vector"
							url={MAP_LABEL_DATASET.url}
							promoteId={MAP_DATASET.propertyId}
						>
							<Layer
								{...MAP_LAYER_LABELS}
							/>
						</Source>
					</Map>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Producteurs suggérés</Typography>
						<Alert severity="info" style={{width: '100%', marginTop: 20}}>
							<AlertTitle>Traitement partiel automatique</AlertTitle>
							La production du producteur {record.store.name} a déjà été <strong>automatiquement
							créée</strong> pour les produits listés ci-dessous.
						</Alert>
					</CardContent>
					{record.products && record.products.length > 0 ?
						<ReferenceManyField
							reference="products"
							source="products"
							target="id"
							pagination={<Pagination/>}
						>
							<DatagridProducers/>
						</ReferenceManyField>
						: <CardContent>
							<Typography variant="body1" gutterBottom>Aucun produit suggéré</Typography>
						</CardContent>
					}
				</Card>
			</Grid>
		</>
	) : null
}
