import SubMenu from '@/components/SubMenu'
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import Box from '@mui/material/Box'
import * as React from 'react'
import {Menu, MenuItemLink, MenuProps, useSidebarState, useTranslate} from 'react-admin'

type MenuName =
    'menuProduits'
    | 'menuTerroirs'
    | 'menuCommunes'
    | 'menuUtilisateurs'
    | 'menuProducteurs'
    | 'menuTags'
    | 'menuMedias'

const AppMenu = ({dense = false}: MenuProps) => {

    const translate = useTranslate()
    const [open] = useSidebarState()

    return (
        <Box
            sx={{
                width: open ? 240 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    })
            }}
        >
            {/*<DashboardMenuItem />*/}
            <Menu.DashboardItem leftIcon={
                <SpaceDashboardOutlinedIcon/>
            }/>

            <Menu.ResourceItem name="products"/>

            <SubMenu
                name="Contributions"
                icon={<DynamicFormOutlinedIcon/>}
                dense={false}
            >
                <Menu.ResourceItem name="contributions"/>
                <Menu.ResourceItem name="app-contributions"/>
            </SubMenu>

            <Menu.ResourceItem name="terroirs"/>
            <Menu.ResourceItem name="communes"/>
            <Menu.ResourceItem name="tags"/>

            <SubMenu
                name="Producteurs"
                icon={<AgricultureOutlinedIcon/>}
                dense={false}
            >
                <Menu.ResourceItem name="producers"/>
                <Menu.ResourceItem name="producers/stores"/>
                <Menu.ResourceItem name="producers/users"/>
                <Menu.ResourceItem name="producers/activities"/>
            </SubMenu>

            {/* UTILISATEURS */}
            <SubMenu
                name="Utilisateurs"
                icon={<ManageAccountsOutlinedIcon/>}
                dense={false}
            >
                <Menu.ResourceItem name="users/admins"/>
                <Menu.ResourceItem name="producers/users"/>
                <Menu.ResourceItem name="users/clients"/>
            </SubMenu>

            <MenuItemLink
                to="/media"
                state={{_scrollToTop: true}}
                primaryText={'Medias'}
                leftIcon={<AttachFileIcon/>}
                dense={dense}
            />
        </Box>
    )
}

export default AppMenu
