import * as React from 'react';
import { useTranslate, useGetList } from 'react-admin';
import CardWithIcon from './CardWithIcon';

import MapRoundedIcon from '@mui/icons-material/MapRounded';
import { Terroir } from '../types';

const TotalTerroirs = () => {
    const translate = useTranslate();
    
    const { total } = useGetList<Terroir>('terroirs');
    return (
        <CardWithIcon
            to="/terroirs"
            icon={MapRoundedIcon}
            title={translate('pos.menu.terroirs')}
            subtitle={total}
        />
    );
};

export default TotalTerroirs;
