import React, {useMemo} from 'react'
import {Box, Typography} from '@mui/material'
import {useFormContext} from 'react-hook-form'

const PasswordStrengthMeter = ({ field }: { field: string }) => {

	const { watch, getFieldState, formState } = useFormContext()
	const password = watch(field)
	const { isTouched } = getFieldState(field, formState)

	const atLeastOneUppercase = /[A-Z]/g // capital letters from A to Z
	const atLeastOneLowercase = /[a-z]/g // small letters from a to z
	const atLeastOneNumeric = /[0-9]/g // numbers from 0 to 9
	const atLeastOneSpecialChar = /[#?!@$%^&*-]/g // any of the special characters within the square brackets
	const eightCharsOrMore = /.{12,}/g // eight characters or more

	const passwordTracker = useMemo(() => {
		return {
			uppercase: password ? password.match(atLeastOneUppercase) : false,
			lowercase: password ? password.match(atLeastOneLowercase) : false,
			number: password ? password.match(atLeastOneNumeric) : false,
			specialChar: password ? password.match(atLeastOneSpecialChar) : false,
			eightCharsOrGreater: password ? password.match(eightCharsOrMore) : false,
		}
	}, [password])

	const passwordStrength = useMemo(() => Object.values(passwordTracker).filter(
		(value) => value
	).length, [password])

	const passLabel = useMemo(() => {
		if (!isTouched)
			return 'Choisissez un mot de passe'
		switch(passwordStrength) {
			case 0:
				return 'Très Faible'
			case 1:
				return 'Faible'
			case 2:
				return 'Moyen'
			case 3:
				return 'Bon'
			case 4:
				return 'Très Bon'
			case 5:
				return 'Excellent'
			default:
				return ''
		}
	}, [passwordStrength])

	const progressColor = useMemo(() => {
		switch(passwordStrength) {
			case 0:
				return '#828282'
			case 1:
				return '#EA1111'
			case 2:
				return '#FFAD00'
			case 3:
				return '#d8ea11'
			case 4:
				return '#9bc158'
			case 5:
				return '#00b500'
			default:
				return 'none'
		}
	}, [passwordStrength])

	return (
		<Box sx={{ padding: '12px 0'}}>
			<Typography style={{ color: progressColor }} gutterBottom>{passLabel}</Typography>
			<div className="progress" style={{ height: '7px' }}>
				<div className="progress-bar" style={{
					width: `${(passwordStrength / 5) * 100}%`,
					background: progressColor,
					height: '7px'
				}}></div>
			</div>
			<Typography sx={{ fontSize: '11px', opacity: '.7', marginTop: '8px !important' }}>12 caractères minimum, 1 majuscule, 1 miniscule, 1 chiffre et 1 caractère spécial.</Typography>
		</Box>
	)
}

export default PasswordStrengthMeter
