import CreatedAtField from '@/components/field/createdAtField'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {BooleanInput, TextInput, useResourceContext} from 'react-admin'

export default function FieldsStatus() {

    const resource = useResourceContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6">
                État du contact
            </Typography>
            <CreatedAtField/>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                <TextInput
                    disabled
                    name="id"
                    source="id"
                    id="identifiant"
                    required
                />
                <BooleanInput
                    label="Activé"
                    name="is_enabled"
                    source="is_enabled"
                />
                {resource !== 'users/admins' &&
                    <>
                        <BooleanInput
                            label="Validé"
                            name="is_validated"
                            source="is_validated"
                        />
                        <BooleanInput
                            label="Producteur"
                            name="is_producer"
                            source="is_producer"
                            disabled
                        />
                    </>
                }
            </Box>
        </Box>
    )
}
