import React from 'react';
import { BooleanField } from "react-admin";
import { green, red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const BooleanNumField = ({ record = {}, source}: any) => {
  const TrueIcon: any = () => {
    return (
      <CheckIcon sx={{ color: green[500] }}/>
    )
  }

  const FalseIcon: any = () => {
    return (
      <CloseIcon sx={{ color: red[500] }}/>
    )
  }
  return <BooleanField source={source} TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>
}

export default BooleanNumField
