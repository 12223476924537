import {ImageListItem} from '@mui/material'
import {useRecordContext} from 'react-admin'

const ImageField = () => {

    const record = useRecordContext()
    return (
        <ImageListItem>
            <img
                src={`${String(process.env.REACT_APP_IMAGE_URL)}${record.path}`}
                srcSet={`${String(process.env.REACT_APP_IMAGE_URL)}${record.path}`}
                alt={record.description}
                loading="lazy"
            />
        </ImageListItem>
    )
}

export default ImageField
