import {BooleanNumField} from '@/components/field'
import {User} from '@/types'
import * as React from 'react'
import {Fragment, ReactNode} from 'react'
import {Datagrid, DatagridProps, DateField, EditButton, FunctionField, TextField} from 'react-admin'

const DatagridActionsColumn = ({label, children}: {
    label: string | ReactNode,
    children: ReactNode
}) => <>{children}</>

export default function DatagridUser(props: DatagridProps) {
    return (
        <Datagrid
            optimized
            rowClick="edit"
            {...props}
        >
            <TextField source="id" label="Identifiant"/>
            <TextField label="Prénom" source="firstName"/>
            <TextField label="Nom" source="lastName"/>
            <TextField label="Email" source="email"/>
            <DateField label="Date de création" source="created_at"/>
            <DateField label="Dernière mise à jour" source="updated_at"/>
            <FunctionField
                source="is_validated"
                label="Compte validé"
                render={(record: User, source: string | undefined) =>
                    <BooleanNumField
                        label="Publié"
                        source={source}
                    />
                }
            />
            <FunctionField
                source="optin_partners"
                label="Opt-in partenaire"
                render={(record: User, source: string | undefined) =>
                    <BooleanNumField
                        label="Publié"
                        source={source}
                    />
                }
            />
            <FunctionField
                source="is_enabled"
                label="Activé"
                render={(record: User, source: string | undefined) =>
                    <BooleanNumField
                        label="Publié"
                        source={source}
                    />
                }
            />
            {props.children}
            <DatagridActionsColumn
                label=""//{<LinkUserDialog/>}
            >
                <EditButton/>
            </DatagridActionsColumn>
        </Datagrid>
    )
}
