import Password from '@/components/form/Password'
import LoginThrough from '@/components/user/loginThrough'
import {Box, Typography} from '@mui/material'
import React from 'react'

export default function FieldsSecurity() {


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Sécurité</Typography>
            <LoginThrough/>
            <Typography gutterBottom>Mot de passe</Typography>
            <Password
                passwordName="new_password"
                confirmationName="new_password_confirmation"
            />
        </Box>
    )
}
