import List from '@/products/ProductList'
import Edit from '@/products/ProductEdit'
import Create from '@/products/ProductCreate'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'

export default {
    icon: CategoryOutlinedIcon,
    list: List,
    edit: Edit,
    create: Create
    // options: {
    //     label: 'Produits V2'
    // },
}
