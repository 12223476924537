import { useEffect } from 'react';
import { Box, Card, CardActions, Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import { useTranslate } from 'react-admin';

import logoLOPT from './logo.webp';

const Welcome = () => {
    const translate = useTranslate();
    useEffect((): any => {
        document.title = "Tableau de bord"
    }, [])

    const styles = {
        btn: {
            color: 'black', 
            backgroundColor: '#D8DDC1',
            transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
                color: 'black',
                backgroundColor: '#D8DDC1',
                transform: 'scale(1.1)'
            }
        }
    }

    return (
        <Card
            sx={{
                background: `#3a4e38`,
                color: '#fff',
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        LOPT Admin
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            Back office de LOPT
                        </Typography>
                    </Box>
                    <CardActions
                        sx={{
                            padding: { xs: 0, xl: null },
                            flexWrap: { xs: 'wrap', xl: null },
                            '& a': {
                                marginTop: { xs: '1em', xl: null },
                                marginLeft: { xs: '0!important', xl: null },
                                marginRight: { xs: '1em', xl: null },
                            },
                        }}
                    >
                        <Button
                            variant="contained"
                            href="https://lopt.org"
                            startIcon={<HomeIcon />}
                            sx={styles.btn}
                        >
                            Accès au site actuel
                        </Button>
                        <Button
                            variant="contained"
                            href="https://github.com/marmelab/react-admin/tree/master/examples/demo"
                            startIcon={<CodeIcon />}
                            sx={styles.btn}
                        >
                            Démo de React Admin
                        </Button>
                    </CardActions>
                </Box>
                <Box
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    sx={{
                        background: `url(${logoLOPT}) top right / cover`,
                        marginLeft: 'auto',
                    }}
                    width="9em"
                    height="9em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;
