import CommuneCreate from './CommuneCreate'
import CommuneEdit from './CommuneEdit'
import CommuneList from './CommuneList'
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined'

export default {
    icon: HolidayVillageOutlinedIcon,
    list: CommuneList,
    edit: CommuneEdit,
    create: CommuneCreate
}
