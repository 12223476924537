import {UserProducer} from '@/types'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {BooleanInput, DateField, TextInput, useRecordContext, useResourceContext} from 'react-admin'

export default function FieldsMarketing() {

    const record = useRecordContext<UserProducer>()
    const resource = useResourceContext()

    return resource !== 'users/admins' ?
        (<Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6">
                Marketing
            </Typography>
            <BooleanInput
                label="Optin partenaire"
                name="optin_partners"
                source="optin_partners"
            />
            <Typography variant="caption" fontWeight={600}>Date de màj. de l'optin</Typography>
            <DateField source="optin_date" showTime sx={{marginBottom: 3}}/>
            <TextInput
                label="Identifiant Brevo"
                name="brevo_id"
                source="brevo_id"
                helperText={
                    record?.brevo_id ? <a
                        href={`https://app.brevo.com/contact/index/${record.brevo_id}`}
                        target={'_blank'}
                    >Voir la fiche dans Brevo
                    </a> : <a
                        href={`https://app.brevo.com/contact/list`}
                        target={'_blank'}
                    >Chercher un contact à lier
                    </a>
                }
            />
        </Box>)
        : null
}
