import {ImageField, URLField} from '@/components/field'
import {Box, IconButton, ImageList, ImageListItem, ImageListItemBar, Tabs, useMediaQuery, useTheme} from '@mui/material'
import {Fragment, useCallback, useEffect, useState} from 'react'
import {Datagrid, List, ListContextProvider, Pagination, TextField, useCreatePath, useListContext} from 'react-admin'
import {HiMenu, HiViewGrid} from 'react-icons/hi'
import Aside from './Aside'
import './style.scss'

const useColsForWidth = () => {
    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    const xl = useMediaQuery(theme.breakpoints.up('xl'))

    if (xl) return 7
    if (lg) return 5
    if (md) return 4
    if (sm) return 3
    return 2
}

const MediaList = () => {

    const [viewMode, setViewMode] = useState('image')

    return (
        <>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'end', gap: '10px', p: '10px'}}>
                <IconButton component="label" className="filter-icon" aria-label="search"
                            onClick={() => setViewMode('list')}>
                    <HiMenu/>
                </IconButton>

                <IconButton component="label" className="filter-icon" aria-label="search"
                            onClick={() => setViewMode('image')}>
                    <HiViewGrid/>
                </IconButton>
            </Box>

            <List
                sort={{field: 'id', order: 'ASC'}}
                perPage={25}
                exporter={false}
                aside={<Aside/>}
            >
                <TabbedDatagrid viewMode={viewMode}/>
            </List>
        </>
    )
}

const TabbedDatagrid = (props: any) => {
    const {viewMode} = props
    const listContext = useListContext()
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters
    } = listContext

    const cols = useColsForWidth()
    const createPath = useCreatePath()

    // ###################
    // TITRE DE LA PAGE
    useEffect((): any => {
        document.title = 'Images'
    }, [])

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                {...filterValues, status: value},
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            )
        },
        [displayedFilters, filterValues, setFilters]
    )

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
            </Tabs>

            <ListContextProvider
                value={{...listContext}}
            >
                <Box display="flex">

                    {viewMode == 'image' ? (
                        <ImageList cols={cols} sx={{width: '100%', height: 1}} variant="woven">
                            {data && data.map((item: any) => {
                                return (
                                    <Box
                                        key={item.id}
                                        className="list-item"
                                        // onClick={() => handleSelect(item)}
                                    >
                                        <ImageListItem
                                            component="a"
                                            href={createPath({
                                                resource: '#/media',
                                                id: item.id,
                                                type: 'edit'
                                            })}
                                            key={item.id}
                                            sx={{height: 217}}
                                        >
                                            <img
                                                src={`${String(process.env.REACT_APP_IMAGE_URL)}${item.path}`}
                                                srcSet={`${String(process.env.REACT_APP_IMAGE_URL)}${item.path}`}
                                                alt={item.description}
                                                className="list-item-img"
                                            />
                                            <ImageListItemBar
                                                title={item.description}
                                                className="list-item-bar"
                                                actionIcon={
                                                    <IconButton
                                                        sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                                        aria-label={`Information about ${item.description}`}
                                                    >
                                                    </IconButton>
                                                }
                                            />
                                        </ImageListItem>
                                    </Box>
                                )
                            })}
                        </ImageList>

                    ) : (
                        <Datagrid size="medium" sx={{'width': '-webkit-fill-available'}} optimized /*rowClick="edit"*/>
                            <TextField source="id" label="resources.medias.fields.id"/>
                            <ImageField/>

                            <URLField source="description" target="media" label="resources.medias.fields.description"/>

                            <TextField source="category.name" label="resources.medias.fields.category"/>

                            <TextField source="metas.size.width" label="resources.medias.fields.width"/>
                            <TextField source="metas.size.height" label="resources.medias.fields.height"/>

                        </Datagrid>
                    )}
                </Box>
            </ListContextProvider>
        </Fragment>
    )
}

export default MediaList
