import CreatedAtField from '@/components/field/createdAtField'
import FullNameField from '@/components/field/FullNameField'
import DefaultEditor from '@/components/form/DefaultEditor'
import PreviewImage from '@/layout/PreviewImage'
import {DeleteButtonProducer} from '@/producers/components/delete'
import FieldsContact from '@/producers/components/fields/fieldsContact'
import FieldsInfos from '@/producers/components/fields/fieldsInfos'
import FieldsLocation from '@/producers/components/fields/fieldsLocation'
import FieldsPresentation from '@/producers/components/fields/fieldsPresentation'
import FieldsPublication from '@/producers/components/fields/fieldsPublication'
import LinkedStoresBulkActions from '@/producers/stores/components/LinkedStoresBulkActions'
import LinkStoreDialog from '@/producers/stores/components/linkStoreDialog'
import DatagridStores from '@/producers/stores/components/listFields'
import LinkedUsersBulkActions from '@/producers/users/components/linkedUsersBulkActions'
import LinkUserDialog from '@/producers/users/components/linkUserDialog'
import DatagridUser from '@/producers/users/components/listFields'
import {Producer} from '@/types'
import {Alert, AlertTitle, Box, Grid, Typography} from '@mui/material'
import React, {ReactNode, useEffect} from 'react'
import {
    Edit,
    Pagination,
    ReferenceManyField,
    SaveButton,
    SimpleForm,
    TabbedShowLayout,
    Toolbar,
    useEditController,
    useNotify
} from 'react-admin'

export const DatagridActionsColumn = ({label, children}: {
    label: string | ReactNode,
    children: ReactNode
}) => <>{children}</>

export default function ProducerEdit() {

    const {record, isLoading: loading} = useEditController<Producer>()

    useEffect(() => {
        if (record) {
            document.title = record.name
        }
    }, [record])

    return (
        <Edit
            mutationMode="pessimistic"
            title={<FullNameField size="32" sx={{margin: '5px 0'}}/>}
        >
            <SimpleForm
                // record={record}
                warnWhenUnsavedChanges
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Typography variant="h4">Modification de l'entreprise</Typography>
                <Typography variant="h6" gutterBottom>{record?.name} {record?.name_enterprise}</Typography>
                <TabbedShowLayout
                    sx={{
                        width: '100%'
                    }}
                >
                    <TabbedShowLayout.Tab label="Présentation">
                        <Grid
                            container
                            spacing={5}
                        >
                            <Grid item xs={12} md={6}>
                                <CreatedAtField/>
                                <FieldsPublication/>
                                <FieldsPresentation/>
                                <FieldsLocation/>
                                <FieldsContact/>
                                <FieldsInfos/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PreviewImage
                                    source="image"
                                    resource="producteur"
                                />
                            </Grid>
                        </Grid>
                        <DefaultEditor/>
                        {record?.description_temp && record?.description_temp !== '' &&
                            <DefaultEditor label="Déscription temporaire" source={'description_temp'}>
                                <Alert severity="info" style={{width: '100%', marginBottom: 20}}>
                                    <AlertTitle>Cette description ne doit pas perdurer</AlertTitle>
                                    La description temporaire provient du fichier producteur d'origine, elle a donc
                                    vocation à être remplacée par la description principale
                                </Alert>
                            </DefaultEditor>
                        }
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Utilisateurs" path="users">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <LinkUserDialog
                                resourceToUpdate="producers"
                            />
                        </Box>
                        <ReferenceManyField
                            reference="producers/users"
                            target="producer_id"
                            pagination={<Pagination/>}
                        >
                            <DatagridUser
                                bulkActionButtons={
                                    <LinkedUsersBulkActions/>
                                }
                            />
                        </ReferenceManyField>
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Établissements" path="stores">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <LinkStoreDialog
                                resourceToUpdate="producers"
                            />
                        </Box>
                        <ReferenceManyField
                            reference="producers/stores"
                            target="producer_id"
                            pagination={<Pagination/>}
                        >
                            <DatagridStores
                                bulkActionButtons={
                                    <LinkedStoresBulkActions/>
                                }
                            />
                        </ReferenceManyField>
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>

            </SimpleForm>
        </Edit>
    )
}

const FormToolbar = () => {

    const notify = useNotify()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
                <SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
                            mutationOptions={{
                                onSuccess: (data) => {
                                    notify('ra.notification.updated', {
                                        type: 'info',
                                        messageArgs: {smart_count: 1}
                                    })
                                }
                            }}/>
            </Box>
            <DeleteButtonProducer/>
        </Toolbar>
    )
}
