import {useEffect, useState} from 'react'
import {
    Create,
    TextInput,
    SelectInput,
    SimpleForm,
    useTranslate,
    Toolbar, 
    SaveButton,
    DeleteButton,
    useNotify,
    useRedirect,
    required
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';

import FullNameField from '../components/field/FullNameField';

const TagsCreate = () => {
    
    const translate = useTranslate();

    const [optionsTypes, setOptionsTypes] = useState<any>([])
    const [optionsCategories, setOptionsCategories] = useState<any>([])

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Nouveau tag"
    }, [])

    useEffect(() => {

        const getOptions = async () => {

            /**
             * Types
             */
            fetch(process.env.REACT_APP_DB_LOPT+'/tags/types')
                .then(res => res.json())
                .then((res) => {
                    setOptionsTypes(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })
            /**
             * Categories
             */
            fetch(process.env.REACT_APP_DB_LOPT+'/tags/categories?_end=null')
                .then(res => res.json())
                .then((res) => {
                    setOptionsCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })

            setLoading(false);
        }

        getOptions()

    }, [])
    
    return (
        <Create title={<TagsTitle />}>
            <SimpleForm 
                warnWhenUnsavedChanges 
                noValidate 
                toolbar={ <FormToolbar />} 
            >
                <Grid container display={{ xs: 'block', sm: 'flex', gap: '50px', placeContent: 'center' }}>
                    <Grid item xs={8} md={8}>
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.tags.fieldGroups.identity'
                            )}
                        </Typography>

                        <TextInput
                            source="name"
                            validate={req}
                            fullWidth
                        />
                
                        <SelectInput label="Type" source="type" optionText="name" choices={optionsTypes} optionValue="id" fullWidth emptyValue="empty" required/>
                        <SelectInput label="Catégorie" source="category" optionText="name" choices={optionsCategories} optionValue="id" fullWidth emptyValue="empty" required/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

const req = [required()];

const TagsTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const FormToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton
                    className="save"
                    label="Enregistrer et quitter"
                    mutationOptions={{
                        onSuccess: event => {
                            // @todo redirect liste
                            notify('ra.notification.created', {
                                type: 'info',
                                messageArgs: { smart_count: 1 },
                            })
                            redirect('/tags')
                        }
                    }}
                />
                <SaveButton
                    className="save"
                    label="Enregistrer"
                    type="button"
                    variant="text"
                />
            </Box>
            <DeleteButton/>
        </Toolbar> 
    );
};

export default TagsCreate;
