import {LayerProps} from 'react-map-gl'

export const MAP_DATASET = {
	sourceId: 'terroirs',
	url: process.env.REACT_APP_MAPBOX_DATASET_URL,
	sourceName: process.env.REACT_APP_MAPBOX_DATASET_SOURCE,
	propertyId: process.env.REACT_APP_MAPBOX_DATASET_PROPERTY
}


export const MAP_LABEL_DATASET = {
	sourceId: 'terroirs-centers',
	url: process.env.REACT_APP_MAPBOX_DATASET_URL_LABEL,
	sourceName: process.env.REACT_APP_MAPBOX_DATASET_SOURCE_LABEL,
	propertyId: process.env.REACT_APP_MAPBOX_DATASET_PROPERTY
}

export const MAP_STYLE = {
	default: 'mapbox://styles/mapbox/streets-v11',
	simple: process.env.REACT_APP_MAPBOX_STYLE
}

export const MAP_LAYER_LABELS = {
	'id': 'terroirs-labels',
	'type': 'symbol',
	'source': MAP_LABEL_DATASET.sourceId,
	'source-layer': MAP_LABEL_DATASET.sourceName,
	'layout': {
		'text-field': [
			'concat',
			['to-string', ['get', 'lib_rz_article']],
			' ',
			['get', 'lib_rz']
		],
		'text-font': [
			'FS Siena Regular',
			'Arial Unicode MS Regular'
		],
		'text-size': [
			'interpolate',
			['linear'],
			['zoom'],
			8,
			10,
			13,
			60
		],
		'text-line-height': 1.2,
		'text-max-width': 10
	},
	'paint': {
		'text-color': [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			'#E8B55F',
			['boolean', ['feature-state', 'hover'], false],
			'#E8B55F',
			'#81A17E'
		],
		'text-opacity': [
			'interpolate',
			['linear'],
			['zoom'],
			0,
			0,
			6,
			0,
			6.3,
			1
		]
	}
} as LayerProps

export const MAP_LAYER_FILL = {
	'source': 'terroirs',
	'id': 'terroirs-fill',
	'type': 'fill',
	'source-layer': MAP_DATASET.sourceName,
	'paint': {
		'fill-outline-color': 'rgba(58, 78, 56, 0.5)',
		'fill-color': [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			'#E8B55F',
			['boolean', ['feature-state', 'hover'], false],
			'#E8B55F',
			'#81A17E'
		],
		'fill-opacity': [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			0.4,
			['boolean', ['feature-state', 'hover'], false],
			0.4,
			['boolean', ['feature-state', 'neighbor'], false],
			0.3,
			0.15
		]
	}
} as LayerProps

export const MAP_LAYER_LINE = {
	'id': 'terroirs-line',
	'type': 'line',
	'source': 'terroirs',
	'source-layer': MAP_DATASET.sourceName,
	'paint': {
		'line-width': 1.5,
		// 'line-color': 'rgba(58, 78, 56, 0.5)',
		'line-color': [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			'#E8B55F',
			['boolean', ['feature-state', 'hover'], false],
			'#E8B55F',
			'#81A17E'
		],
		'line-opacity': [
			'case',
			['boolean', ['feature-state', 'selected'], false],
			1,
			['boolean', ['feature-state', 'hover'], false],
			1,
			0.5
		]
	}
} as LayerProps
