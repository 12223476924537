import {User} from '@/types'
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {RaRecord, useDataProvider, useNotify, useRecordContext, useRefresh} from 'react-admin'

interface LinkUserInterface {
    resourceToUpdate: string
}

export default function LinkUserDialog<T extends RaRecord>({resourceToUpdate}: LinkUserInterface) {

    const [open, setOpen] = useState(false)
    const [value, setValue] = useState<User | null>(null)
    const record = useRecordContext<T>()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const handleSave = async () => {
        if (value) {
            await dataProvider.update(resourceToUpdate, {
                id: record.id,
                data: {
                    ...record,
                    ...{
                        users: [
                            ...record.users,
                            value.id
                        ]
                    }
                },
                previousData: record
            })
            refresh()
            notify('Utilisateur ajouté', {type: 'success'})
        }
        handleClose()
    }

    const [users, setUsers] = useState<User[]>([])
    const [inputValue, setInputValue] = useState('')

    const updateOptions = useCallback((inputValue: string) => {
        dataProvider.getList('producers/users', {
            filter: {
                q: inputValue
            },
            sort: {field: 'id', order: 'DESC'},
            pagination: {page: 1, perPage: 25}
        })
            .then(({data}) => {
                setUsers(data)
            })
    }, [inputValue])

    useEffect(() => {
        updateOptions(inputValue)
    }, [inputValue])

    useEffect(() => {
        updateOptions('')
    }, [])

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                Lier un utilisateur
            </Button>

            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle>Lier un utilisateur au producteur {record.name ?? record.name_enterprise}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={users}
                        noOptionsText={'Aucun résultat'}
                        getOptionLabel={(option) => `${option.firstName} -- ${option.email}`}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        filterOptions={(options) => options.filter(option => {
                            return !record.users.includes(option.id)
                        })}
                        onChange={(event, newValue) => {
                            setValue(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Sélectionnez un utilisateur" fullWidth/>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};
