import InsertLinkIcon from '@mui/icons-material/More'
import {ToggleButton} from '@mui/material'
import {isNodeSelection, mergeAttributes, Node, nodeInputRule} from '@tiptap/core'
import {NodeSelection, TextSelection} from '@tiptap/pm/state'
import {useTiptapEditor} from 'ra-input-rich-text'
import React, {useCallback} from 'react'
import './MoreButton.scss'
import {useNotify} from 'react-admin'

export interface HorizontalRuleOptions {
    /**
     * The HTML attributes for a horizontal rule node.
     * @default {}
     * @example { class: 'foo' }
     */
    HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        specialTag: {
            /**
             * Set a code block
             * @param attributes Code block attributes
             * @example editor.commands.insertSpecialTag()
             */
            insertSpecialTag: () => ReturnType
        }
    }
}

export default function MoreButton() {

    const editor = useTiptapEditor()
    const notify = useNotify()
    const insertMoreTag = useCallback(() => {
        if (!editor.chain().focus().insertSpecialTag().run()) {
            notify('Le lire la suite est déjà inséré.', {type: 'error'})
        }
    }, [editor])

    return editor ? (
        <>
            <ToggleButton
                aria-label="Tronquer à partir d'ici"
                title="Tronquer à partir d'ici"
                value="link"
                sx={{mR: 8, mB: 8, p: '11px'}}
                onClick={insertMoreTag}
            >
                <InsertLinkIcon fontSize="inherit"/>
            </ToggleButton>
        </>
    ) : null
}

export const SpecialTag = Node.create<HorizontalRuleOptions>({
    name: 'specialTag',

    addOptions() {
        return {
            HTMLAttributes: {
                ['data-more']: 'true'
            }
        }
    },

    group: 'block',

    renderHTML({HTMLAttributes}) {
        return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-more]'
            }
        ]
    },

    addCommands() {
        return {
            insertSpecialTag:
                () => ({chain, state, editor}) => {

                    // // Rechercher si le document contient déjà un nœud `specialTag`
                    // const {doc, tr} = editor.state
                    // let specialTagPos = null
                    // let specialTagNodeSize = 0
                    //
                    // // Parcourir les nœuds du document pour trouver le tag existant
                    // doc.descendants((node, pos) => {
                    //     console.log(node, node.type.name, this.name, node.type.name === this.name)
                    //     if (node.type.name === this.name) {
                    //         console.log('specialTag trouvé', pos, node.nodeSize)
                    //         specialTagPos = pos // Enregistrer la position du tag existant
                    //         specialTagNodeSize = node.nodeSize
                    //     }
                    // })
                    //
                    // if (specialTagPos !== null) {
                    //     // Si un tag est trouvé, le supprimer
                    //     console.log('Suppression du tag spécial')
                    //     tr.delete(specialTagPos, specialTagPos + specialTagNodeSize) // Supprimer le nœud existant
                    //     editor.view.dispatch(tr) // Appliquer la transaction pour supprimer le tag
                    // }
                    // @ts-ignore
                    const hasSpecialTag = editor.state.doc.content.content.some((node) => node.type.name === this.name)

                    if (hasSpecialTag) {
                        // Bloquer l'insertion si une instance existe déjà
                        console.warn('Le tag spécial est déjà inséré.')
                        return false
                    }

                    const {selection} = state
                    const {$from: $originFrom, $to: $originTo} = selection

                    const currentChain = chain()

                    if ($originFrom.parentOffset === 0) {
                        currentChain.insertContentAt(
                            {
                                from: Math.max($originFrom.pos - 1, 0),
                                to: $originTo.pos
                            },
                            {
                                type: this.name
                            }
                        )
                    } else if (isNodeSelection(selection)) {
                        currentChain.insertContentAt($originTo.pos, {
                            type: this.name
                        })
                    } else {
                        currentChain.insertContent({type: this.name})
                    }

                    return (
                        currentChain
                            // set cursor after horizontal rule
                            .command(({tr, dispatch}) => {
                                if (dispatch) {

                                    const {$to} = tr.selection
                                    const posAfter = $to.end()

                                    if ($to.nodeAfter) {
                                        if ($to.nodeAfter.isTextblock) {
                                            tr.setSelection(TextSelection.create(tr.doc, $to.pos + 1))
                                        } else if ($to.nodeAfter.isBlock) {
                                            tr.setSelection(NodeSelection.create(tr.doc, $to.pos))
                                        } else {
                                            tr.setSelection(TextSelection.create(tr.doc, $to.pos))
                                        }
                                    } else {
                                        // add node after horizontal rule if it’s the end of the document
                                        const node = $to.parent.type.contentMatch.defaultType?.create()

                                        if (node) {
                                            tr.insert(posAfter, node)
                                            tr.setSelection(TextSelection.create(tr.doc, posAfter + 1))
                                        }
                                    }

                                    tr.scrollIntoView()
                                }

                                return true
                            })
                            .run()
                    )
                }
        }
    },
    addInputRules() {
        return [
            nodeInputRule({
                find: /^(?:---|—-|___\s|\*\*\*\s)$/,
                type: this.type
            })
        ]
    }
})
