import LinearProgressField from '@/components/field/LinearProgressField'
import Aside from '@/contributions/Aside'
import * as React from 'react'
import {Fragment, useCallback, useEffect} from 'react'
import {Datagrid, DateField, FunctionField, List, Pagination, TextField, useCreatePath} from 'react-admin'
import {Link} from 'react-router-dom'

const ContributionList = () => (
    <List
        sort={{field: 'id', order: 'DESC'}}
        perPage={25}
        exporter={false}
        aside={<Aside/>}
    >
        <TableList/>
    </List>
)

const TableList = () => {

    const createPath = useCreatePath()

    useEffect((): any => {
        document.title = 'Contributions'
    }, [])

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>
            <Datagrid
                size="medium"
                sx={{'width': '-webkit-fill-available'}}
                optimized
                rowClick="show"
            >
                <TextField source="id"/>
                <TextField source="user.email" label="Email"/>
                {/* <FunctionField
                    source="email"
                    label="Email"
                    render={(record: any) => // @todo type Contribution
                        <Link
                            to={createPath({ resource: 'users', type: 'edit', id: record.user.id })}
                            onClick={handleClick}
                            style={{ textDecoration: 'none' }}
                        >
                            <TextField source="user.email" color="primary" />
                        </Link>
                    }
                /> */}
                <LinearProgressField source="progress" label="Progression"/>
                <FunctionField
                    source="terroir.name"
                    label="Terroir"
                    render={(record: any) => // @todo type Contribution
                        <Link
                            to={createPath({resource: 'terroirs', type: 'edit', id: record.terroir.id})}
                            onClick={handleClick}
                            style={{textDecoration: 'none'}}
                        >
                            {record?.terroir.code} -- {record?.terroir.name}
                        </Link>
                    }
                />
                <DateField source="created_at" label="Date de création"/>
                <DateField source="updated_at" label="Date de modification"/>
            </Datagrid>
        </Fragment>
    )
}

export default ContributionList
