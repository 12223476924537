import * as React from 'react';
import { useTranslate, useGetList } from 'react-admin';
import CardWithIcon from './CardWithIcon';

import InventoryIcon from '@mui/icons-material/Inventory';

import { Product } from '../types';

const TotalProduits = () => {
    const translate = useTranslate();

    const { total } = useGetList<Product>('products');
    return (
        <CardWithIcon
            to="/products"
            icon={InventoryIcon}
            title={translate('pos.menu.produits')}
            subtitle={total}
        />
    );
};

export default TotalProduits;
