import {BooleanNumField} from '@/components/field'
import {Commune, ProducerStore} from '@/types'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {IconButton} from '@mui/material'
import * as React from 'react'
import {ReactNode} from 'react'
import {Datagrid, DatagridProps, EditButton, FunctionField, ReferenceField, TextField} from 'react-admin'
import {getProducerStoreLink} from '@/lib/link'

const DatagridActionsColumn = ({label, children}: {
	label: string | ReactNode,
	children: ReactNode
}) => <>{children}</>

export default function DatagridStores(props: DatagridProps) {
	return (
		<Datagrid
			optimized
			rowClick="edit"
			{...props}
		>
			<TextField source="id" label="Identifiant"/>
			<TextField label="Nom" source="name"/>
			{/*<TextField label="Nom" source="lastName"/>*/}
			<TextField label="Email" source="email"/>
			<TextField label="Téléphone" source="phone"/>
			{/*<DateField label="Date de création" source="created_at"/>*/}
			{/*<DateField label="Dernière mise à jour" source="updated_at"/>*/}
			{/*<FunctionField*/}
			{/*    source="is_validated"*/}
			{/*    label="Compte validé"*/}
			{/*    render={(record: any, source: any) =>*/}
			{/*        <BooleanNumField*/}
			{/*            label="Publié"*/}
			{/*            source={source}*/}
			{/*        />*/}
			{/*    }*/}
			{/*/>*/}
			<ReferenceField source="commune" reference="communes" label="Commune">
				<FunctionField
					source="is_direct_selling"
					label="Vente direct"
					render={(record: Commune) =>
						<span>{record.name}, {record.zipcode}</span>
					}
				/>
			</ReferenceField>
			<FunctionField
				source="is_direct_selling"
				label="Vente direct"
				render={(record: any, source: any) =>
					<BooleanNumField
						label="Publié"
						source={source}
					/>
				}
			/>
			<FunctionField
				source="is_organic"
				label="Bio"
				render={(record: any, source: any) =>
					<BooleanNumField
						label="Publié"
						source={source}
					/>
				}
			/>
			<DatagridActionsColumn
				label=""//{<LinkUserDialog/>}
			>
				<EditButton/>
				<FunctionField
					source="is_organic"
					label="Bio"
					render={(record: ProducerStore) =>
						<IconButton
							target="_blank"
							href={getProducerStoreLink(record)}
							color="primary"
							aria-label="View"
						>
							<OpenInNewIcon/>
						</IconButton>
					}
				/>
			</DatagridActionsColumn>
		</Datagrid>
	)
}
