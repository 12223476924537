import FullNameField from '@/components/field/FullNameField'
import DefaultEditor from '@/components/form/DefaultEditor'
import PreviewImage from '@/layout/PreviewImage'
import FieldsContact from '@/producers/stores/components/fields/fieldsContact'
import FieldsInfos from '@/producers/stores/components/fields/fieldsInfos'
import FieldsLocation from '@/producers/stores/components/fields/fieldsLocation'
import FieldsPresentation from '@/producers/stores/components/fields/fieldsPresentation'
import FieldsPublication from '@/producers/stores/components/fields/fieldsPublication'
import FieldsSell from '@/producers/stores/components/fields/fieldsSell'
import LinkedUsersBulkActions from '@/producers/users/components/linkedUsersBulkActions'
import LinkUserDialog from '@/producers/users/components/linkUserDialog'
import DatagridUser from '@/producers/users/components/listFields'
import {ProducerStore} from '@/types'
import {Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {
    DeleteButton,
    Edit,
    Pagination,
    ReferenceManyField,
    SaveButton,
    SimpleForm,
    TabbedShowLayout,
    Toolbar,
    useEditController,
    useNotify
} from 'react-admin'

export default function ProducerStoreEdit() {

    const {record, isLoading: loading} = useEditController<ProducerStore>()

    useEffect(() => {
        if (record) {
            document.title = record.name
        }
    }, [record])

    return (
        <Edit
            mutationMode="pessimistic"
            title={<FullNameField size="32" sx={{margin: '5px 0'}}/>}
        >
            <SimpleForm
                // record={record}
                warnWhenUnsavedChanges
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Typography variant="h4">Modification de l'établissement</Typography>
                <Typography variant="h6" gutterBottom>{record?.name}</Typography>
                <TabbedShowLayout
                    sx={{
                        width: '100%'
                    }}
                >
                    <TabbedShowLayout.Tab label="Présentation">
                        <Grid
                            container
                            spacing={5}
                        >
                            <Grid item xs={12} md={6} display={'flex'} flexDirection={'column'} gap={3}>
                                <FieldsPublication/>
                                <FieldsPresentation/>
                                <FieldsSell/>
                                <FieldsContact/>
                                <FieldsInfos/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PreviewImage
                                    source="image"
                                    resource="produit"
                                />
                                <FieldsLocation/>
                            </Grid>
                        </Grid>
                        <DefaultEditor/>
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="Utilisateurs autorisés" path="users">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <LinkUserDialog
                                resourceToUpdate="producers/stores"
                            />
                        </Box>
                        <ReferenceManyField
                            reference="producers/users"
                            target="store_id"
                            pagination={<Pagination/>}
                        >
                            <DatagridUser
                                bulkActionButtons={
                                    <LinkedUsersBulkActions/>
                                }
                            />
                        </ReferenceManyField>
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </SimpleForm>
        </Edit>
    )
}

const FormToolbar = () => {

    const notify = useNotify()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
                <SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
                            mutationOptions={{
                                onSuccess: (data) => {
                                    notify('ra.notification.updated', {
                                        type: 'info',
                                        messageArgs: {smart_count: 1}
                                    })
                                }
                            }}/>
            </Box>
            <DeleteButton/>
        </Toolbar>
    )
}
