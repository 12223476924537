import LabelLinked from '@/components/form/LabelLinked'
import fetchApi from '@/lib/tools/api'
import {normalizeString} from '@/lib/tools/utils'
import {Commune, ProductCommune} from '@/types'
import ContentSave from '@mui/icons-material/Save'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, {Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {useCreatePath, useNotify, useSaveContext} from 'react-admin'
import fireEvent from 'react-app-events/lib/fireEvent'
import {RiCloseCircleFill} from 'react-icons/ri'


const PARAMS = {
	context: 'commune',
	endpoints: {
		search: () => `/communes`,
		getLinked: (id: number) => `/products/${id}/communes`,
		save: (id: number) => `/products/${id}/communes`,
		add: (id: number) => `/products/${id}/communes/add`,
		delete: (id: number) => `/products/${id}/communes/remove`
	}
}

export default function ProductCommunes({productId}: { productId: number }) {

	const notify = useNotify()
	const createPath = useCreatePath()
	const {saving} = useSaveContext()

	const [filter, setFilter] = useState('')
	const [options, setOptions] = useState<Commune[]>([])
	const [touched, setTouched] = useState<boolean>(false)

	const [linkedOptions, setLinkedOptions] = useState<ProductCommune[]>([])

	const [loading, setLoading] = useState(false)
	const [loadingSearch, setLoadingSearch] = useState(false)
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (saving)
			handleSave()
	}, [saving])

	/**
	 * Event
	 * Find linked product's commune
	 */
	useEffect(() => {
		const getLinked = async () => {
			setLoading(true)

			/**
			 * Liaisons
			 */
			await fetchApi<ProductCommune[]>(
				PARAMS.endpoints.getLinked(productId)
			)
				.then((res: ProductCommune[]) => {
					setLinkedOptions(res ?
						// Tri par nom
						res.sort((a: ProductCommune, b: ProductCommune) => a.commune.name.localeCompare(b.commune.name))
						: []
					)
				})

			setLoadingSearch(false)
			setLoading(false)
		}

		getLinked()

	}, [])

	/**
	 * Methods
	 * Search options
	 */
	const handleSearch = useCallback(async (event: SyntheticEvent<Element, Event>, value: string) => {
		// React.ChangeEvent<HTMLInputElement>
		// SyntheticEvent<Element, Event>
		const {target} = event

		// @todo throttle request
		setLoadingSearch(true)

		/**
		 * Communes
		 */
		const results = await fetchApi<Commune[]>(
			PARAMS.endpoints.search(),
			undefined,
			{q: value}
		)
		const resultsSorted = results.sort((a: Commune, b: Commune) => a.name.localeCompare(b.name))

		setOptions(resultsSorted)
		setLoadingSearch(false)
	}, [])

	/**
	 * Methods
	 * Update rows values
	 */
	const updateLinked = useCallback((id: number, key: string, value: boolean | number) => {
		setLinkedOptions((linked) => {
			return linked.map((row: ProductCommune) => {
				return row.id === id ? {
					...row,
					...{
						[key]: value
					}
				} : row
			})
		})

		setTouched(true)
	}, [])

	/**
	 * Methods
	 * Save/Add/Remove row
	 */

	const handleSelect = useCallback((event: SyntheticEvent, item: Commune | null) => {

		if (!item) return

		setLoadingSearch(true)

		fetchApi<ProductCommune>(
			PARAMS.endpoints.add(productId),
			{id: item.id},
			undefined,
			'PUT'
		)
			.then((res: ProductCommune) => {
				notify('Liaison ajoutée', {
					type: 'info',
					messageArgs: {smart_count: 1}
				})
				setLoadingSearch(false)
				setLinkedOptions((linked) => {
					return [
						...linked,
						...[res]
					]
				})
				setFilter(`${res.commune.code_insee} -- ${res.commune.name}`)
			})

	}, [])

	const handleSave = useCallback(() => {

		fetchApi(
			PARAMS.endpoints.save(productId),
			{linkedOptions},
			undefined,
			'PUT'
		)
			.then(() => {
				setTouched(false)
				notify('Liaisons sauvegardées', {
					type: 'info',
					messageArgs: {smart_count: 1}
				})
				fireEvent('PRODUCTS_COMMUNES_SAVED', true)
			})
		setFilter('')

	}, [linkedOptions])

	const handleRemove = useCallback((item: ProductCommune) => {
		if (confirm('Supprimer la liaison ?')) {
			fetchApi(
				PARAMS.endpoints.delete(productId),
				{id: item.id},
				undefined,
				'PUT'
			)
				.then(res => {
					if (res) {
						setLinkedOptions((linked) =>
							linked.filter(t => t.id !== item.id)
						)
						notify('Liaison supprimée', {
							type: 'info',
							messageArgs: {smart_count: 1}
						})
					}
				})
		}
	}, [])

	const linkedOptionsFiltered = useMemo(() => linkedOptions.filter((item: ProductCommune) =>
		normalizeString(item.commune.code_insee + ' -- ' + item.commune.name).indexOf(normalizeString(filter)) >= 0
	), [filter, linkedOptions])

	return (
		<Box>
			<Typography variant="h6" gutterBottom>Communes liées</Typography>
			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: '16px',
				gap: '12px'
			}}>
				{linkedOptions.length > 0 &&
                    <TextField
                        value={filter}
                        variant="outlined"
                        label="Rechercher une liaison"
                        sx={{flex: '1 0 50%'}}
                        onChange={({target: {value}}) => setFilter(value)}
                    />
				}
				<Autocomplete
					id="search_communes"
					noOptionsText={'Aucun résultat'}
					renderInput={(params) =>
						<TextField variant="outlined" {...params} label="Ajouter une liaison" InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loadingSearch ?
										<CircularProgress sx={{marginRight: '30px'}} color="inherit" disableShrink
										                  size={20}/> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							)
						}}/>
					}
					getOptionDisabled={(option) => !!linkedOptions.find(o => o[PARAMS.context].id === option.id)}
					size="small"
					sx={{flex: '1 0 50%'}}
					options={options}
					getOptionLabel={(option: Commune) => `${option.code_insee} -- ${option.name}`}
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					filterOptions={(options) => options.map(option => {
						option.linked = linkedOptions.find(t => t[PARAMS.context].id === option.id)
						return option
					})}
					onInputChange={handleSearch}
					onChange={handleSelect}
				/>
			</Box>
			<Box sx={{display: 'flex', gap: '16px', flexDirection: 'column', alignItems: 'flex-start'}}>
				<Paper
					sx={{
						width: '100%',
						height: 300,
						overflow: 'hidden',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{loading && <CircularProgress disableShrink size="5rem"/>}
					{!loading &&
                        <TableContainer sx={{height: 300}}>
                            <Table stickyHeader={true} aria-label="sticky table" size="small"
                                   sx={{height: 'max-content', border: 'black'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: 300}}> Libellé </TableCell>
                                        <TableCell>Est 1932</TableCell>
                                        <TableCell>Est 1992</TableCell>
                                        <TableCell>Est 2022</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
									{linkedOptionsFiltered.map((item: ProductCommune) => (
										<TableRow key={item.id}>
											<TableCell>
												{`${item.commune.code_insee} -- `}
												<LabelLinked resource="communes" label={`${item.commune.name}`}
												             id={item.commune.id}/>
												<Typography
													sx={{fontStyle: 'italic', fontWeight: 'normal', fontSize: '12px'}}
													variant="subtitle2">
													{item.commune.zipcode}
												</Typography>
											</TableCell>
											<TableCell>
												<Checkbox
													id={item.id + '_1932'}
													onChange={({target}) => updateLinked(item.id, 'is1932', target.checked)}
													checked={item.is1932}
												/>
											</TableCell>
											<TableCell>
												<Checkbox
													id={item.id + '_1992'}
													onChange={({target}) => updateLinked(item.id, 'is1992', target.checked)}
													checked={item.is1992}
												/>
											</TableCell>
											<TableCell>
												<Checkbox
													id={item.id + '_2022'}
													onChange={({target}) => updateLinked(item.id, 'is2022', target.checked)}
													checked={item.is2022}
												/>
											</TableCell>
											<TableCell>
												<IconButton sx={{}} onClick={() => handleRemove(item)}>
													<RiCloseCircleFill/>
												</IconButton>
											</TableCell>
										</TableRow>
									))}
                                </TableBody>
                            </Table>
                        </TableContainer>
					}
				</Paper>
				<Button disabled={!touched} variant="contained" sx={{gap: '8px'}} onClick={handleSave}><ContentSave
					height={18} width={18}/>Enregistrer</Button>
			</Box>
		</Box>
	)
};
