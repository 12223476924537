import Show from '@/app-contributions/show'
import List from '@/app-contributions/list'
import {AppContribution, AppContributionTerroir} from '@/types'
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined'

export default {
	icon: DynamicFormOutlinedIcon,
	list: List,
	show: Show
}

export function getLabelFromType(type: AppContribution['type']) {
	switch (type) {
		case 'AppContributionProduct':
			return 'Produit'
		case 'AppContributionTerroir':
			return 'Terroir'
		case 'AppContributionProducerStore':
			return 'Établissement'
		case 'AppContributionMapStore':
			return 'Carte - Établissement'
		default:
			return 'Inconnu'
	}
}

export function getStatusLabel(status: AppContribution['status']) {
	switch (status) {
		case 0:
			return 'Créée'
		case 1:
			return 'Traitement partiel'
		case 2:
			return 'Traité'
		default:
			return 'Inconnu'
	}
}

export function getStatusClass(status: AppContribution['status']) {
	switch (status) {
		case 0:
			return 'default'
		case 1:
			return 'warning'
		case 2:
			return 'success'
		default:
			return 'default'
	}
}

export function getLabelFromSubject(status: AppContributionTerroir['listing_subject']) {
	switch (status) {
		case 'product':
			return 'Produits'
		case 'specialty':
			return 'Spécialités'
		default:
			return 'Inconnu'
	}
}
