import Password from '@/components/form/Password'
import {Alert, Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {
    ArrayInput,
    AutocompleteInput,
    Create,
    DeleteButton, ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm, SimpleFormIterator,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect
} from 'react-admin'
import CreatedAtField from '@/components/field/createdAtField'
import PreviewImage from '@/layout/PreviewImage'
import MapField from '@/components/field/mapField'
import FieldsPublication from '@/producers/components/fields/fieldsPublication'
import FieldsPresentation from '@/producers/components/fields/fieldsPresentation'
import FieldsContact from '@/producers/components/fields/fieldsContact'
import FieldsInfos from '@/producers/components/fields/fieldsInfos'
import { default as FieldsInfosStore } from '@/producers/stores/components/fields/fieldsInfos'
import FieldsLocation from '@/producers/components/fields/fieldsLocation'
import FieldsSell from '@/producers/stores/components/fields/fieldsSell'

export default function ProducerCreate() {

    useEffect(() => {
        document.title = 'Ajouter une entreprise'
    }, [])

    return (
        <Create
            title={'Ajouter une entreprise'}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                noValidate
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Typography variant="h4" gutterBottom>Création d'une entreprise</Typography>
                <Alert severity="info" style={{width: '100%', marginBottom: 20}}>Une fiche <strong>établissement</strong> sera créée automatiquement à partir de l'entreprise</Alert>
                {/*<Typography gutterBottom>{record?.name_enterprise}</Typography>*/}

                <Grid
                    container
                    spacing={5}
                >
                    <Grid item xs={12} md={6}>
                        <FieldsPublication/>
                        <FieldsPresentation/>
                        <FieldsContact/>
                        <FieldsInfos/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldsLocation displayMap={true}/>
                        <Typography gutterBottom variant={'h5'}>Renseignements pour l'établissement</Typography>
                        <FieldsSell/>
                        <FieldsInfosStore/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

const req = [required()]

const FormToolbar = () => {

    const notify = useNotify()
    const redirect = useRedirect()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton
                    className="save"
                    label="Enregistrer"
                    mutationOptions={{
                        onSuccess: event => {
                            // @todo redirect liste
                            notify('ra.notification.created', {
                                type: 'info',
                                messageArgs: {smart_count: 1}
                            })
                            redirect('/users/admins', 'products')
                        }
                    }}
                />
            </Box>
            <DeleteButton/>
        </Toolbar>
    )
}
