import * as React from 'react';
import { useTranslate, useGetList } from 'react-admin';
import CardWithIcon from './CardWithIcon';

import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Commune } from '../types';

const TotalCommunes = () => {
    const translate = useTranslate();

    const { total } = useGetList<Commune>('communes');
    return (
        <CardWithIcon
            to="/communes"
            icon={LocationCityIcon}
            title={translate('pos.menu.communes')}
            subtitle={total}
        />
    );
};

export default TotalCommunes;
