import ProducerUserCreate from '@/producers/users/create'
import ProducerUserEdit from '@/producers/users/edit'
import ProducerUsersList from '@/producers/users/list'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'

export default {
    icon: SupervisorAccountOutlinedIcon,
    list: ProducerUsersList,
    edit: ProducerUserEdit,
    create: ProducerUserCreate
}
