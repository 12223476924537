import { useEffect } from 'react';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    AutocompleteArrayInput,
    ReferenceArrayInput,
    useListFilterContext,
    SelectInput,
    required,
    Form,
} from 'react-admin';
import { styles, transitions } from '@/products/Aside'
import { Box, BoxProps, Typography } from '@mui/material';

interface propsTerroirsFilter extends BoxProps {
    active: boolean;
}

const FilterTerroirs = (props: propsTerroirsFilter) => {

    const { active, onClick } = props
    const PARAMS = {
        label: 'Terroirs',
        icon: <MapRoundedIcon />,
    }

    const { filterValues, setFilters } = useListFilterContext();

    const handleTerroirsChange = (event: React.ChangeEvent) => {
        setFilters({ ...filterValues, terroirs: event }, true);
    };

    useEffect(() => {
        return () => {
            setFilters({ terroirs: [] }, true)
        };
    }, []);

    return (
        <Form>
            <Box sx={styles.filter}>
                <Box mt={2} display="flex" alignItems="center" onClick={onClick}>
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>{PARAMS.icon}</Box>
                        <Typography variant="overline">
                            {PARAMS.label}
                        </Typography>
                    </Box>
                    {active ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </Box>
                {
                    <Box sx={{ ...styles.buttons, ...transitions(active, '0.4s') }}>
                        <ReferenceArrayInput
                            name="terroirs"
                            label="Terroirs"
                            reference="terroirs"
                            source="terroirs"
                        >
                            <>
                                <AutocompleteArrayInput
                                    blurOnSelect={false}
                                    name="terroirs"
                                    optionText="name"
                                    multiple={true}
                                    optionValue="id"
                                    debounce={250}
                                    onChange={handleTerroirsChange}
                                    size='medium'
                                    defaultValue={filterValues?.terroirs}
                                />

                            </>
                        </ReferenceArrayInput>
                    </Box>
                }
            </Box>
        </Form>
    );
};

export default FilterTerroirs;


