import {Pagination, ReferenceManyField, useEditController} from 'react-admin'
import {AppContributionTerroir} from '@/types'
import {Alert, AlertTitle, Card, CardContent, CardHeader, Chip, Grid, Typography} from '@mui/material'
import {getLabelFromSubject} from '@/app-contributions'
import TerroirCardInfo from '@/terroirs/components/terroirCardInfo'
import ProductsDatagrid from '@/products/components/datagrid'
import React from 'react'

export default function EditTerroirContribution() {

	const {record, isLoading: loading} = useEditController<AppContributionTerroir>()

	return record ? (
		<>
			{record.description_accuracy !== null &&
                <Grid item xs={6}>
                    <Card sx={{height: '100%'}}>
                        <CardHeader title={'Fiche terroir'}/>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Question</Typography>
                            <Typography variant="body1" gutterBottom>Est-ce que la description vous convient
                                ?</Typography>
                            <Chip
                                label={record.description_accuracy ? 'Oui' : 'Non'}
                                color={record.description_accuracy ? 'success' : 'error'}
                                size="small"
                            />
                        </CardContent>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Comment décrireriez-vous ce terroir
                                ?</Typography>
                            <Typography variant="body1" gutterBottom>{record.description || 'N/A'}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
			}
			{record.listing_subject !== null &&
                <Grid item xs={6}>
                    <Card sx={{height: '100%'}}>
                        <CardHeader title={`Listing ${getLabelFromSubject(record.listing_subject)}`}/>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Question</Typography>
                            <Typography variant="body1" gutterBottom>Est-ce que selon vous il manque un
                                [{getLabelFromSubject(record.listing_subject)}]
                                au terroir ?</Typography>
                            <Chip
                                label={record.listing_accuracy ? 'Oui' : 'Non'}
                                color={record.listing_accuracy ? 'error' : 'success'}
								// variant="outlined"
                                size="small"
                            />
                        </CardContent>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Avez-vous d’autres informations à nous communiquer ?
                            </Typography>
                            <Typography variant="body1" gutterBottom>{record.description || 'N/A'}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
			}
			<Grid item xs={6}>
				{record.terroir &&
                    <TerroirCardInfo terroir={record.terroir}/>
				}
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Produits suggérés</Typography>
						<Alert severity="info" style={{width: '100%', marginTop: 20}}>
							<AlertTitle>Traitement partiel automatique</AlertTitle>
							Les produits suggérés dans la contribution ont été <strong>automatiquement
							ajoutés</strong> dans le
							terroir de la contribution s'ils n'existaient pas déjà. Auquel cas leur indice de
							confiance a été augmenté.
						</Alert>
					</CardContent>
					{record.products.length > 0 ?
						<ReferenceManyField
							reference="products"
							source="products"
							target="id"
							pagination={<Pagination/>}
						>
							<ProductsDatagrid/>
						</ReferenceManyField>
						: <CardContent>
							<Typography variant="body1" gutterBottom>Aucun produit suggéré</Typography>
						</CardContent>
					}
				</Card>
			</Grid>
		</>
	) : null
}
