import {AuthProvider} from 'react-admin'
import {UserInterface} from './types'

interface AuthResponseInterface {
    code?: number
    message: string
    status?: number,
    token: string,
    refresh_token: string,
    refresh_token_expiration: number,
    user: UserInterface
}

interface InvalidateResponseInterface {
    code: number,
    message: string
}

function getExpiration(nbHours = 1) {
    const now = new Date()
    return now.getTime() + ((60 * 60 * 1000) * nbHours)
}

export const authProvider: AuthProvider = {
    login: async ({email, password}) => {

        const data = await loginAction(email, password)

        if (data.token) {
            localStorage.setItem('user', JSON.stringify(data.user))
            localStorage.setItem('token', data.token)
            localStorage.setItem('token_expiration', getExpiration().toString())
            localStorage.setItem('refresh_token', data.refresh_token)
            // localStorage.setItem('refresh_token_expiration', data.refresh_token_expiration.toString())

            return Promise.resolve(data)

        } else {
            return Promise.reject(data)
        }
    },
    logout: async () => {
        if (localStorage.getItem('token')) {
            invalidateAction()
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            localStorage.removeItem('token_expiration')
            localStorage.removeItem('refresh_token')
            // localStorage.removeItem('refresh_token_expiration')
        }
        return Promise.resolve()
    },
    checkError: ({status}) => {
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve()
    },
    checkAuth: async () => {

        const now = new Date().getTime()
        const localExpiration = localStorage.getItem('token_expiration')
        const expiration = localExpiration ? parseInt(localExpiration) : 0

        // no token or expiration time
        if (!localStorage.getItem('token') || !expiration) {
            return Promise.reject()
        }

        // if now exceed expiration minus 10s
        if (now >= (expiration - 10000)) {
            const res = await refreshAction()
            if (res) {
                return Promise.resolve()
            }
        } else {
            // not expired
            return Promise.resolve()
        }

        // to token, expiration, refresh failed
        return Promise.reject()
    },
    getPermissions: () => {
        // const role = localStorage.getItem('role');
        return Promise.resolve()
    },
    getIdentity: () => {
        const user = JSON.parse(localStorage.getItem('user') as string)
        return Promise.resolve({
            id: String(user.id),
            fullName: String(user.firstName)
            // avatar: String(avatar),
        })
    }
}

function loginAction(username: string, password: string): Promise<AuthResponseInterface> {
    return new Promise((resolve, reject) => {

        const request = new Request(String(process.env.REACT_APP_DB_AUTH), {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'})
        })

        fetch(request)
            .then(response => {
                // if (response.status < 200 || response.status >= 300) {
                //     throw new Error(response.statusText);
                // }
                return response.json()
            })
            .then((data: AuthResponseInterface) => {
                resolve(data)
            })
        // .catch((e) => {
        //     console.error(e)
        //     reject(e)
        // })
    })
}

function invalidateAction(): Promise<InvalidateResponseInterface> {
    return new Promise((resolve, reject) => {

        const request = new Request(String(process.env.REACT_APP_DB_INVALID), {
            method: 'POST',
            body: JSON.stringify({refresh_token: localStorage.getItem('refresh_token') as string}),
            headers: new Headers({'Content-Type': 'application/json'})
        })

        fetch(request)
            .then(response => response.json())
            .then((data: InvalidateResponseInterface) => {
                if (data.code === 200) {
                    resolve(data)
                } else {
                    reject(data)
                }
            })
            .catch((e) => {
                console.error(e)
                reject(e)
            })
    })
}

export function refreshAction(): Promise<boolean> {
    return new Promise((resolve, reject) => {

        if (!localStorage.getItem('refresh_token')) {
            reject()
        }

        const request = new Request(String(process.env.REACT_APP_DB_REFRESH), {
            method: 'POST',
            body: JSON.stringify({refresh_token: localStorage.getItem('refresh_token') as string}),
            headers: new Headers({'Content-Type': 'application/json'})
        })

        fetch(request)
            .then(response => response.json())
            .then((data: AuthResponseInterface) => {
                if (data.token) {
                    localStorage.setItem('user', JSON.stringify(data.user))
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('token_expiration', getExpiration().toString())
                    // localStorage.setItem('refresh_token_expiration', data.refresh_token_expiration.toString())
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch((e) => {
                console.error(e)
                reject(e)
            })
    })
}

function checkAction(): Promise<boolean> {
    return new Promise((resolve, reject) => {

        const request = new Request(String(process.env.REACT_APP_DB_CHECK), {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            })
        })

        fetch(request)
            .then(response => response.json())
            .then((data: AuthResponseInterface) => {
                if (data.status === 200 && data.user) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {
                resolve(false)
            })
    })
}
