import TagList from './TagList'
import TagEdit from './TagEdit'
import TagCreate from './TagCreate'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'

export default {
    icon: StyleOutlinedIcon,
    list: TagList,
    edit: TagEdit,
    create: TagCreate
}
