import {Producer} from '@/types'
import {Box, Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useDataProvider, useListContext, useNotify, useRecordContext, useRefresh, useUnselectAll} from 'react-admin'

export default function LinkedStoresBulkActions() {

    const {selectedIds, resource} = useListContext()
    const dataProvider = useDataProvider()
    const record = useRecordContext<Producer>()
    const unselectAll = useUnselectAll('producers/stores')
    const refresh = useRefresh()
    const notify = useNotify()
    const unlink = useCallback(async () => {
        const filtered = record.users.filter(id => !selectedIds.includes(id))
        await dataProvider.update(`producers`, {
            id: record.id,
            data: {
                ...record,
                ...{
                    users: filtered
                }
            },
            previousData: record
        })
        unselectAll()
        refresh()
        notify('La sélection a été déliée', {type: 'success'})
    }, [])

    return (
        <Box>
            <Button onClick={unlink} variant="contained" color="error">Délier la sélection</Button>
        </Box>
    )
}
