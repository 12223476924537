import { useEffect } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { Grid, Box, Typography } from '@mui/material'
import { FullNameField } from '@/components/field'
import MediaUpload from '@/components/media/MediaUpload'

const MediaCreate = () => {

    // ###################
    // TITRE DE LA PAGE
    useEffect((): any => {
        document.title = "Nouvelle image"
    }, [])

    return (
        <Create title={<ImageTitle />}>
            <SimpleForm warnWhenUnsavedChanges noValidate toolbar={false} >
                <Grid>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Importer une image
                        </Typography>

                        <Box display={{ xs: 'block', md: 'flex', gap: '20px', justifyContent: 'space-between' }}>
                            <MediaUpload />

                        </Box>
                    </Box>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

const ImageTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default MediaCreate;