import {User, UserProducer} from '@/types'
import {Box, Card, CardContent, Typography} from '@mui/material'
import React from 'react'

export default function UserCardInfo({user}: { user: User | UserProducer }) {

	const {roles} = user

	const hasUserRole = roles.includes('ROLE_USER')
	const hasProducerRole = roles.includes('ROLE_PRODUCER')

	return (
		<Card>
			<CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
				<Box>
					<Typography variant="h6" gutterBottom>
						Informations sur le contributeur
					</Typography>
					<Box ml={2}>
						<Typography variant="body1" gutterBottom>
							<strong>ID:</strong> {user.id}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Civilité:</strong> {user.civility}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Email:</strong> {user.email}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Prénom:</strong> {user.firstName}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Nom:</strong> {user.lastName}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Rôles:</strong>{' '}
							{hasUserRole && <span>Utilisateur</span>}
							{hasUserRole && hasProducerRole && <span>, </span>}
							{hasProducerRole && <span>Producteur</span>}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Créé le:</strong> {user.created_at}
						</Typography>
						<Typography variant="body1" gutterBottom>
							<strong>Mis à jour le:</strong> {user.updated_at}
						</Typography>
					</Box>
				</Box>
			</CardContent>
		</Card>
	)
}
