import SaveIcon from '@mui/icons-material/Save'
import {Paper, Typography} from '@mui/material'
import React from 'react'

export default function BeforeSave () {
	return (
		<Paper
			variant="outlined"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '8px',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '60px'
			}}
		>
			<SaveIcon color={"primary"}/>
			<Typography variant="body2" gutterBottom>
				Sauvegardez d'abord l'élément
			</Typography>
		</Paper>
	)
}
