import React from 'react'

import Welcome from './Welcome'
import TotalTerroirs from './TotalTerroirs'
import TotalCommunes from './TotalCommunes'
import TotalUsers from './TotalUsers'
import TotalProduits from './TotalProduits'
import { useMediaQuery } from '@mui/material'


const Dashboard = () => {
    const isWide = useMediaQuery('(min-width:1024px)');
    
    const styles = {
        flex: { display: 'flex', gap: '20px', flexFlow: isWide ? 'initial' : 'column'},
        flexColumn: { display: 'flex', flexDirection: 'column' },
        leftCol: { flex: 1, marginRight: '0.5em' },
        rightCol: { flex: 1, width: '-webkit-fill-available' },
        singleCol: { marginTop: '1em', marginBottom: '1em' },
    };
    return (
        <>
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <TotalTerroirs />
                        <TotalCommunes />
                        <TotalUsers />
                        <TotalProduits />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
