import {Box, Typography} from '@mui/material'
import {
    ArrayInput,
    AutocompleteInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TextInput
} from 'react-admin'
import React from 'react'
import MapField from '@/components/field/mapField'

export default function FieldsLocation({ displayMap = false }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Localisation</Typography>
            {displayMap &&
                <MapField
                    source="coordinates"
                />
            }
            <TextInput
                label="Adresse"
                name="address"
                source="address"
                fullWidth
                validate={[required()]}
            />
            <TextInput
                label="Adresse secondaire"
                name="address_secondary"
                source="address_secondary"
                fullWidth
            />
            <ReferenceInput
                name="commune"
                source="commune"
                reference="communes"
            >
                <AutocompleteInput
                    label="Commune"
                    name="commune"
                    optionText={option => `${option.zipcode} -- ${option.name}`}
                    optionValue="id"
                    debounce={100}
                />
            </ReferenceInput>
        </Box>
    )
}
