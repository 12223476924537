import {Box, Typography} from '@mui/material'
import {
    ArrayInput,
    AutocompleteInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TextInput
} from 'react-admin'
import React from 'react'

export default function FieldsPresentation() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Présentation</Typography>
            <TextInput
                label="Nom commercial"
                name="name"
                source="name"
                fullWidth
                // helperText="Nom à afficher au client"
            />
            <TextInput
                label="Nom de l'entreprise"
                name="name_enterprise"
                source="name_enterprise"
                fullWidth
                validate={[required()]}
            />
            <ReferenceInput
                name="activity"
                source="activity"
                reference="producers/activities"
            >
                <AutocompleteInput
                    label="Activité principale"
                    name="activity"
                    blurOnSelect={false}
                    optionText="name"
                    optionValue="id"
                    debounce={100}
                />
            </ReferenceInput>
            <ReferenceInput
                name="activity_secondary"
                source="activity_secondary"
                reference="producers/activities"
            >
                <AutocompleteInput
                    label="Activité secondaire"
                    name="activity_secondary"
                    blurOnSelect={false}
                    optionText="name"
                    optionValue="id"
                    debounce={100}
                />
            </ReferenceInput>
        </Box>
    )
}
