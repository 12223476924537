import fetchApi from '@/lib/tools/api'
import {User} from '@/types'
import {Box, Button, Typography} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useRecordContext, useResourceContext} from 'react-admin'

type LoginThroughProps = {
	user: User | null
	login_link: string | null
}

export default function LoginThrough() {

	const resource = useResourceContext()
	const record = useRecordContext()

	const loginThrough = useCallback(() => {

		if (!record) return

		fetchApi<LoginThroughProps>('/users/login_through', {
			user_id: record.id
		}, {}, 'POST').then((response) => {
			if (response.user && response.login_link) {
				window.open(response.login_link, '_blank')
			}
		})

	}, [record])

	const showLoginThrough = useMemo(() => resource === 'producers/users', [resource])

	return showLoginThrough ? (
		<Box marginBottom={2}>
			<Typography gutterBottom>Connexion</Typography>
			<Button
				onClick={loginThrough}
				variant={'contained'}
				size={'small'}
				color={'warning'}
			>
				Se connecter au compte
			</Button>
		</Box>
	) : null
}
