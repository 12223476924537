import * as React from 'react';
import { Fragment, useCallback, useState, useEffect } from 'react';
import {
    Datagrid,
    List,
    ListContextProvider,
    SearchInput,
    TextField,
    DateField,
    TextInput,
    useListContext,
    useTranslate,
    Pagination
} from 'react-admin';
import { useMediaQuery, Divider, Tabs, Tab, Theme, Box } from '@mui/material';

import Aside from './Aside';
import { URLField } from '@/components/field';

const TagsList = () => (
    <List
    sort={{ field: 'id', order: 'ASC' }}
    perPage={25}
    exporter={false}
    >
        <TabbedDatagrid />
    </List>
);

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const {
        data,
        total,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading
    } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
        );

    const translate = useTranslate();

    // ###################
    // TITRE DE LA PAGE
    useEffect((): any => {
        document.title = "Tags"
    }, [])

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters,
                    false
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
            </Tabs>

            <Divider />

            {isXSmall ? (
                <ListContextProvider
                    value={{ ...listContext}}
                >
                    {/* <MobileGrid /> */}
                </ListContextProvider>
            ) : (
                <>
                    <ListContextProvider
                        value={{ ...listContext}}

                    >
                        <Box display="flex">
                            <Datagrid size="medium" sx={{"width" : "-webkit-fill-available"}} optimized /*rowClick="edit"*/>
                                <TextField source="id" label={translate('resources.tags.fields.id')} />
                                <URLField source="name" target="tags"/>
                                <TextField source="categoryName" label={translate('resources.tags.fields.categorie')}/>
                                <TextField source="typeName" label={translate('resources.tags.fields.type')}/>
                            </Datagrid>

                            <Aside />
                        </Box>
                    </ListContextProvider>
                </>
            )}
        </Fragment>
    );
};

export default TagsList;
