import {Box, Typography} from '@mui/material'
import React from 'react'
import {DateField} from 'react-admin'

export default function CreatedAtField() {
    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px'
        }} paddingY={2}>
            <Box>
                <Typography variant="caption" fontWeight={600}>Date de mise à jour</Typography><br/>
                <DateField source="updated_at" showTime/>
            </Box>
            <Box>
                <Typography variant="caption" fontWeight={600}>Date de création</Typography><br/>
                <DateField source="created_at" showTime/>
            </Box>
        </Box>
    )
}
