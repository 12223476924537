import {Commune} from '@/types'
import * as React from 'react'
import {Fragment, useCallback, useEffect} from 'react'
import {Datagrid, FunctionField, Link, List, Pagination, SearchInput, TextField, useCreatePath} from 'react-admin'

export default function TerroirList() {
    return (
        <List
            sort={{field: 'code', order: 'ASC'}}
            perPage={25}
            filters={[<SearchInput name="q" source="q" alwaysOn/>]}
            exporter={false}
        >
            <TableList/>
        </List>
    )
}

const TableList = () => {

    const createPath = useCreatePath()

    useEffect((): any => {
        document.title = 'Terroirs'
    }, [])

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <Datagrid
                optimized
                rowClick="edit"
            >
                <TextField source="id"/>
                <TextField source="code" label="Code RZ"/>
                <FunctionField
                    source="name"
                    label="Libellé"
                    render={(record: Commune) =>
                        <Link
                            to={createPath({resource: 'terroirs', type: 'edit', id: record.id})}
                            onClick={handleClick}
                            style={{textDecoration: 'none'}}
                        >
                            <TextField source="name" color="primary"/>
                            <TextField source="name_alt" color="black" sx={{
                                display: 'block',
                                fontStyle: 'italic',
                                color: 'black !important',
                                fontSize: '12px'
                            }}/>
                        </Link>
                    }
                />
            </Datagrid>
        </Fragment>
    )
}
