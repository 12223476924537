import UserList from '@/users/clients/List'
import UserEdit from '@/users/clients/Edit'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'

export default {
    icon: SupervisorAccountOutlinedIcon,
    list: UserList,
    edit: UserEdit,
    // create: CommuneCreate
}
