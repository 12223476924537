import FieldsIdentity from '@/users/components/fields/identity'
import FieldsMarketing from '@/users/components/fields/marketing'
import FieldsRoles from '@/users/components/fields/roles'
import FieldsSecurity from '@/users/components/fields/security'
import FieldsStatus from '@/users/components/fields/status'
import {Box, Grid} from '@mui/material'
import React, {useEffect} from 'react'
import {Create, DeleteButton, required, SaveButton, SimpleForm, Toolbar, useNotify} from 'react-admin'

export default function UserAdminCreate() {

    useEffect(() => {
        document.title = 'Ajouter un utilisateur'
    }, [])

    return (
        <Create
            title={'Ajouter un utilisateur'}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                noValidate
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Grid
                    container
                    spacing={5}
                >
                    <Grid item xs={12} md={6} display={'flex'} flexDirection={'column'} gap={3}>
                        <FieldsStatus/>
                        <FieldsIdentity/>
                        <FieldsMarketing/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldsSecurity/>
                        <FieldsRoles isCreate={true}/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

const req = [required()]

const FormToolbar = () => {

    const notify = useNotify()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton
                    className="save"
                    label="Enregistrer"
                    mutationOptions={{
                        onSuccess: event => {
                            notify('Administrateur créé', {
                                type: 'success',
                                messageArgs: {smart_count: 1}
                            })
                        }
                    }}
                />
            </Box>
            <DeleteButton/>
        </Toolbar>
    )
}
