import {SimpleShowLayout, TextField, useEditController} from 'react-admin'
import {AppContributionMapStore} from '@/types'
import {Card, CardContent, CardHeader, Chip, Grid, Stack, Typography} from '@mui/material'
import TerroirCardInfo from '@/terroirs/components/terroirCardInfo'
import React, {useRef, useState} from 'react'
import Map, {Layer, MapRef, Marker, Source} from 'react-map-gl'
import {
	MAP_DATASET,
	MAP_LABEL_DATASET,
	MAP_LAYER_FILL,
	MAP_LAYER_LABELS,
	MAP_LAYER_LINE,
	MAP_STYLE
} from '@/lib/map/map'

export default function EditMapStoreContribution() {

	const {record, isLoading: loading} = useEditController<AppContributionMapStore>()

	const mapRef = useRef<MapRef | null>(null)

	const [viewState, setViewState] = useState({
		zoom: 8,
		...(record && {
			longitude: record.lng,
			latitude: record.lat
		})
	})

	return record ? (
		<>
			<Grid item xs={12} md={6}>
				<Card sx={{height: '100%'}}>
					<CardHeader title={'Suggestion d\'établissement'}/>
					<CardContent>
						<Stack gap={3}>
							<SimpleShowLayout>
								<TextField label="Nom" source="name"/>
								<TextField label="Adresse" source="address"/>
								<TextField label="Code postal" source="zipcode"/>
								<TextField label="Ville" source="city"/>
							</SimpleShowLayout>
							<div>
								<Typography variant="subtitle2" gutterBottom>Pratique la vente à distance</Typography>
								<Chip
									label={record.is_vad ? 'Oui' : 'Non'}
									color={record.is_vad ? 'success' : 'error'}
									size="small"
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Pratique la vente sur place</Typography>
								<Chip
									label={record.is_direct_selling ? 'Oui' : 'Non'}
									color={record.is_direct_selling ? 'success' : 'error'}
									size="small"
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Bio ?</Typography>
								<Chip
									label={record.is_organic ? 'Oui' : 'Non'}
									color={record.is_organic ? 'success' : 'error'}
									size="small"
								/>
							</div>
							<div>
								<Typography variant="subtitle2" gutterBottom>Description</Typography>
								<Typography variant="body1" gutterBottom>{record.description || 'N/A'}</Typography>
							</div>
						</Stack>
					</CardContent>
					{record.email &&
                        <CardContent>
                            <Typography variant="subtitle2" gutterBottom>Email de contact fourni</Typography>
                            <Typography variant="body1" gutterBottom>{record.email}</Typography>
                        </CardContent>
					}
				</Card>
			</Grid>
			<Grid item xs={12} md={6}>
				<Card sx={{height: '100%'}}>
					<Map
						ref={mapRef}
						initialViewState={viewState}
						style={{width: '100%', height: '100%'}}
						// mapStyle="mapbox://styles/mapbox/streets-v9"
						mapStyle={MAP_STYLE['simple']}
						mapboxAccessToken={process.env.REACT_APP_MAPBOX}
						interactiveLayerIds={['terroirs-fill']}
						// onLoad={onLoad}
					>
						{/*<Geocoder*/}
						{/*    mapRef={mapRef}*/}
						{/*    onViewportChange={handleGeocoderViewportChange}*/}
						{/*    mapboxApiAccessToken={MAPBOX_TOKEN}*/}
						{/*    position="top-left"*/}
						{/*/>*/}
						<Marker
							longitude={record.lng}
							latitude={record.lat}
						/>
						<Source
							id="terroirs"
							type="vector"
							url={MAP_DATASET.url}
							promoteId={MAP_DATASET.propertyId}
						>
							<Layer
								{...MAP_LAYER_FILL}
							/>
							<Layer
								{...MAP_LAYER_LINE}
							/>
						</Source>
						<Source
							id={MAP_LABEL_DATASET.sourceId}
							type="vector"
							url={MAP_LABEL_DATASET.url}
							promoteId={MAP_DATASET.propertyId}
						>
							<Layer
								{...MAP_LAYER_LABELS}
							/>
						</Source>
					</Map>
				</Card>
			</Grid>
			<Grid item xs={12} md={6}>
				{record.terroir &&
                    <TerroirCardInfo terroir={record.terroir}/>
				}
			</Grid>
		</>
	) : null
}
