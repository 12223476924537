import {Alert, Box, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {CheckboxGroupInput, useResourceContext} from 'react-admin'

export const ROLES_ADMIN = [
    {id: 'ROLE_ADMIN', name: 'Administrateur'},
    {id: 'ROLE_CONTRIBUTOR', name: 'Contributeur'},
    {id: 'ROLE_CONTRIBUTOR_TERROIR', name: 'Contributeur - terroir'},
    {id: 'ROLE_CONTRIBUTOR_COMMUNE', name: 'Contributeur - commune'},
    {id: 'ROLE_CONTRIBUTOR_PRODUCT', name: 'Contributeur - produit'},
    {id: 'ROLE_USER', name: 'Utilisateur'}
]

export const ROLES_DEFAULT = [
    {id: 'ROLE_USER', name: 'Utilisateur'},
    {id: 'ROLE_PRODUCER', name: 'Producteur'}
]
export default function FieldsRoles({isCreate = false}: { isCreate?: boolean }) {

    const resource = useResourceContext()

    const roles = useMemo(() => {
        switch (resource) {
            case 'users/admins':
                return ROLES_ADMIN
            default:
                return ROLES_DEFAULT
        }
    }, [resource])

    const defaultRoles = useMemo(() => {
        if (!isCreate) return []
        switch (resource) {
            case 'users/admins':
                return ['ROLE_USER']
            case 'producers/users':
                return ROLES_DEFAULT.map(role => role.id)
            default:
                return []
        }
    }, [resource])
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Gestion des rôles</Typography>
            <Alert severity={'warning'}>Modifiez les rôles uniquement si vous savez ce que vous faites !</Alert>
            <CheckboxGroupInput
                label="Gestion des rôles"
                name="roles"
                source="roles"
                helperText="Attention à ne pas attribuer des rôles trop importants"
                row={false}
                choices={roles}
                defaultValue={defaultRoles}
            />
        </Box>
    )
}
