import {useEffect, useState} from 'react'
import { Card, CardContent } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CategoryIcon from '@mui/icons-material/Category';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList,
} from 'react-admin';

const Aside = () => {

    const [types, setTypes] = useState([])
    const [categories, setCategories] = useState([])

    const [loading, setLoading] = useState(true);
    // ###################
    // CHARGEMENT DES DONNEES
    useEffect(() => {
        const getOptions = async () => {

            /**
             * Types
             */
            fetch(process.env.REACT_APP_DB_LOPT+'/tags/types')
                .then(res => res.json())
                .then((res) => {
                    setTypes(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })
            /**
             * Categories
             */
            fetch(process.env.REACT_APP_DB_LOPT+'/tags/categories?_end=null')
                .then(res => res.json())
                .then((res) => {
                    setCategories(res.sort((a: any, b: any) => a.name.localeCompare(b.name)))
                })

            setLoading(false);
        }

        if (loading) {
            getOptions()
        }

    }, [])

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <FilterList
                    label="Types"
                    icon={<FilterListIcon />}
                >

                    {types && types.map((type: any) => (
                        <FilterListItem
                            key={"type" + type.id}
                            label={String(type.name)}
                            value={{ type: type.id }}
                        /> 
                    ))}
                    
                </FilterList>

                <FilterList
                    label="Catégories"
                    icon={<CategoryIcon />}
                >
                    <FilterListItem
                        key="categorie0"
                        label="Non catégorisé"
                        value={{ category: 11 }}
                        /> 

                    {categories && categories.filter((data: any) => data.name != "Non catégorisé").map((category: any) => (
                        <FilterListItem
                            key={"categorie" + category.id}
                            label={String(category.name)}
                            value={{ category: category.id }}
                        /> 
                    ))}

                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;