import {BooleanNumField} from '@/components/field'
import {BulkDeleteProducer} from '@/producers/components/delete'
import {Commune, Producer} from '@/types'
import {Typography} from '@mui/material'
import * as React from 'react'
import {ReactNode} from 'react'
import {Datagrid, DatagridProps, EditButton, FunctionField, ReferenceField, TextField} from 'react-admin'

const DatagridActionsColumn = ({label, children}: {
    label: string | ReactNode,
    children: ReactNode
}) => <>{children}</>

export default function DatagridProducers(props: DatagridProps) {
    return (
        <Datagrid
            optimized
            rowClick="edit"
            bulkActionButtons={
                <BulkDeleteProducer/>
            }
            {...props}
        >
            <TextField source="id" label="Identifiant"/>
            <FunctionField
                label="Dénomination"
                render={(record: Producer) =>
                    <>
                        <Typography variant={'subtitle1'}>{record.name}</Typography>
                        <Typography variant={'subtitle2'}>{record.name_enterprise}</Typography>
                    </>
                }
            />
            {/*<TextField source="name" label="Nom commercial"/>*/}
            {/*<TextField source="name_enterprise" label="Entreprise"/>*/}
            <ReferenceField source="commune" reference="communes" label="Commune">
                <FunctionField
                    label="Vente direct"
                    render={(record: Commune) =>
                        <span>{record.name}</span>
                    }
                />
            </ReferenceField>
            {/*<TextField source="email" label="Email principal"/>*/}

            <ReferenceField source="activity" label="Activité" reference="producers/activities">
                <TextField source="name"/>
            </ReferenceField>
            {/*<ReferenceField source="activity_secondary" label="Activité secondaire"*/}
            {/*                reference="producers/activities">*/}
            {/*    <TextField source="name"/>*/}
            {/*</ReferenceField>*/}
            <FunctionField
                source="is_validated"
                label="Validé"
                render={(record: any, source: any) =>
                    <BooleanNumField
                        label="Validé"
                        source={source}
                    />
                }
            />
            <FunctionField
                source="is_published"
                label="Publié"
                render={(record: any, source: any) =>
                    <BooleanNumField
                        label="Publié"
                        source={source}
                    />
                }
            />
            <DatagridActionsColumn
                label=""//{<LinkUserDialog/>}
            >
                <EditButton/>
            </DatagridActionsColumn>
        </Datagrid>
    )
}
