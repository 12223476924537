import LinkedProducersBulkActions from '@/producers/components/linkedProducersBulkActions'
import LinkProducerDialog from '@/producers/components/linkProducerDialog'
import DatagridProducers from '@/producers/components/datagrid'
import LinkedStoresBulkActions from '@/producers/stores/components/LinkedStoresBulkActions'
import LinkStoreDialog from '@/producers/stores/components/linkStoreDialog'
import DatagridStores from '@/producers/stores/components/listFields'
import {UserProducer} from '@/types'
import FieldsIdentity from '@/users/components/fields/identity'
import FieldsMarketing from '@/users/components/fields/marketing'
import FieldsRoles from '@/users/components/fields/roles'
import FieldsSecurity from '@/users/components/fields/security'
import FieldsStatus from '@/users/components/fields/status'
import {Box, Grid, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {
    DeleteButton,
    Edit,
    Pagination,
    ReferenceManyField,
    required,
    SaveButton,
    SimpleForm,
    TabbedShowLayout,
    Toolbar,
    useEditController,
    useNotify
} from 'react-admin'

export default function ProducerUserEdit() {

	const {record} = useEditController<UserProducer>()

	useEffect(() => {
		if (record) {
			document.title = 'Utilisateur'
		}
	}, [record])

	return (
		<Edit
			mutationMode="pessimistic"
			title={'Utilisateur'}
		>
			<SimpleForm
				record={record}
				warnWhenUnsavedChanges
				noValidate
				toolbar={<FormToolbar/>}
				sx={{
					padding: {
						md: '32px',
						xs: '16px'
					}
				}}
			>
				<Typography variant="h4">Modification de l'utilisateur</Typography>
				<TabbedShowLayout
					sx={{
						width: '100%'
					}}
				>
					<TabbedShowLayout.Tab label="Présentation">
						<Grid
							container
							spacing={5}
						>
							<Grid item xs={12} md={6} display={'flex'} flexDirection={'column'} gap={3}>
								<FieldsStatus/>
								<FieldsIdentity/>
								<FieldsMarketing/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FieldsSecurity/>
								<FieldsRoles/>
							</Grid>
						</Grid>
					</TabbedShowLayout.Tab>
					{record?.is_producer &&
                        <TabbedShowLayout.Tab label="Entreprises liées" path={'enterprises'}>
                            <Box
                                sx={{
									display: 'flex',
									justifyContent: 'flex-end'
								}}
                            >
                                <LinkProducerDialog/>
                            </Box>
                            <ReferenceManyField
                                reference="producers"
                                target="user_id"
                                pagination={<Pagination/>}
                            >
                                <DatagridProducers
                                    bulkActionButtons={
										<LinkedProducersBulkActions/>
									}
                                />
                            </ReferenceManyField>
                        </TabbedShowLayout.Tab>
					}
					{record?.is_producer &&
                        <TabbedShowLayout.Tab label="Établissements" path="stores">
                            <Box
                                sx={{
									display: 'flex',
									justifyContent: 'flex-end'
								}}
                            >
                                <LinkStoreDialog
                                    resourceToUpdate="producers/users"
                                />
                            </Box>
                            <ReferenceManyField
                                reference="producers/stores"
                                target="user_id"
                                pagination={<Pagination/>}
                            >
                                <DatagridStores
                                    bulkActionButtons={
										<LinkedStoresBulkActions/>
									}
                                />
                            </ReferenceManyField>
                        </TabbedShowLayout.Tab>
					}
				</TabbedShowLayout>
			</SimpleForm>
		</Edit>
	)
}

const req = [required()]

const FormToolbar = () => {

	const notify = useNotify()

	return (
		<Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
			<Box sx={{display: 'flex', gap: 5}}>
				<SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
				<SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
				            mutationOptions={{
					            onSuccess: (data) => {
						            notify('ra.notification.updated', {
							            type: 'success',
							            messageArgs: {smart_count: 1}
						            })
					            }
				            }}/>
			</Box>
			<DeleteButton/>
		</Toolbar>
	)
}
