import {Alert, Box, Typography} from '@mui/material'
import {ArrayInput, SelectInput, SimpleFormIterator, TextInput} from 'react-admin'
import React from 'react'

export default function FieldsContact() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Informations de contact</Typography>
            <Alert severity="warning" style={{width: '100%', marginBottom: 20}}>Attention à bien remplir les informations de contact</Alert>
            <TextInput
                label="Email"
                name="email"
                source="email"
                fullWidth
                // validate={[required()]}
            />
            <TextInput
                label="Téléphone"
                name="phone"
                source="phone"
                fullWidth
                // validate={[required()]}
            />
            <TextInput
                label="Site web"
                name="website"
                source="website"
                fullWidth
                // validate={[required()]}
            />
            <Typography variant={'h6'}>Réseaux sociaux</Typography>
            <ArrayInput
                name="social"
                source="social"
                label=""
            >
                <SimpleFormIterator inline>
                    <SelectInput source="name" choices={[
                        {id: 'facebook', name: 'Facebook'},
                        {id: 'instagram', name: 'Instagram'},
                        {id: 'twitter', name: 'Twitter'}
                    ]}/>
                    <TextInput source="url"/>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    )
}
