import * as React from 'react';
import { Fragment, useEffect } from 'react';
import {
    Datagrid,
    List,
    SearchInput,
    TextField,
    Pagination, ReferenceField
} from 'react-admin'

export default function ProducerActivityList () {
    return (
        <List
            sort={{ field: 'name', order: 'ASC' }}
            perPage={25}
            filters={[<SearchInput name="q" source="q" alwaysOn />]}
            exporter={false}
        >
            <TableList />
        </List>
    )
}

const TableList = () => {

    useEffect((): any => {
        document.title = "Activités producteurs"
    }, [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
            <Datagrid
                optimized
                rowClick="edit"
            >
                <TextField source="id" label="Identifiant"/>
                <TextField source="name" label="Nom" />
                <TextField source="description" label="Description" />
            </Datagrid>
        </Fragment>
    )
}
