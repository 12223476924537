import TerroirList from './TerroirList'
import TerroirEdit from './TerroirEdit'
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined'

export default {
    icon: LandscapeOutlinedIcon,
    list: TerroirList,
    edit: TerroirEdit
    // create: TerroirCreate
}
