import {Box, Chip, Grid, Skeleton, Stack, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {DateField, ShowBase, useEditController, useNotify} from 'react-admin'
import './style.css'
import {getLabelFromType, getStatusClass, getStatusLabel} from '@/app-contributions/index'
import {AppContributionTypes} from '@/types'
import UserCardInfo from '@/components/user/userCardInfo'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import EditTerroirContribution from '@/app-contributions/components/editTerroirContribution'
import EditMapStoreContribution from '@/app-contributions/components/editMapStoreContribution'
import EditProductContribution from '@/app-contributions/components/editProductContribution'
import EditProducerStoreContribution from '@/app-contributions/components/editProducerStoreContribution'
import fetchApi from '@/lib/tools/api'
import LoadingButton from '@mui/lab/LoadingButton'

const ContributionShow = () => {

	const notify = useNotify()

	const {
		record,
		isLoading: loading,
		refetch
	} = useEditController<AppContributionTypes>()

	useEffect(() => {
		if (record) {
			document.title = `Contribution ${getLabelFromType(record.type)} #${record.id}`
		}
	}, [record, loading])

	const [updateLoading, setUpdateLoading] = useState(false)

	const updateStatus = useCallback(async (status: number) => {
		if (record) {
			setUpdateLoading(true)
			const request = await fetchApi<AppContributionTypes>(`/app-contributions/${record.id}/update`, {status}, null, 'PATCH')
			await refetch()
			if (request.id) {
				notify('Contribution mise à jour', {
					type: 'info'
				})
			} else {
				notify('Une erreur est survenue', {
					type: 'error'
				})
			}
			setUpdateLoading(false)
		}
	}, [record])

	const markAsDone = useCallback(async () => {
		updateStatus(2)
	}, [record])

	const markAsPartiallyDone = useCallback(async () => {
		updateStatus(1)
	}, [record])

	const resetStatus = useCallback(async () => {
		updateStatus(0)
	}, [record])

	return record ? (
		<ShowBase>
			<Box p="1em">
				<Typography variant="h6" gutterBottom>Contribution #{record.id}</Typography>
				<Typography variant="h4" gutterBottom>
					{loading ? <Skeleton/> : getLabelFromType(record.type)}
				</Typography>
				<Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} justifyItems={'flex-start'} spacing={2} marginBottom={2}>
					<div>
						<Typography variant="subtitle2">Créée le</Typography>
						<DateField source="created_at" showTime/>
					</div>
					<div>
						<Typography variant="subtitle2">Mis à jour le</Typography>
						<DateField source="updated_at" showTime/>
					</div>
				</Stack>
				<Grid container rowSpacing={3} columnSpacing={{xs: 3, sm: 3, md: 3}}>
					<Grid item xs={12}>
						<Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} justifyItems={'flex-start'} spacing={2}>
							<Chip
								label={getStatusLabel(record.status)}
								color={getStatusClass(record.status)}
							/>
							<div>|</div>
							<LoadingButton
								loading={updateLoading}
								variant="contained"
								color="inherit"
								size={'small'}
								endIcon={<ArrowForwardOutlinedIcon/>}
								onClick={resetStatus}
							>
								{record.status === 2 ? 'Réinitialiser le statut' : 'À traiter'}
							</LoadingButton>
							{record.status !== 1 &&
                                <LoadingButton
                                    loading={updateLoading}
                                    variant="contained"
                                    color="warning"
                                    size={'small'}
                                    endIcon={<ArrowForwardOutlinedIcon/>}
                                    onClick={markAsPartiallyDone}
                                >
                                    Traitement partiel
                                </LoadingButton>
							}
							{record.status !== 2 &&
                                <LoadingButton
                                    loading={updateLoading}
                                    variant="contained"
                                    color="success"
                                    size={'small'}
                                    endIcon={<ArrowForwardOutlinedIcon/>}
                                    onClick={markAsDone}
                                >
                                    Contribution traitée
                                </LoadingButton>
							}
						</Stack>
					</Grid>
					{record.type === 'AppContributionTerroir' && <EditTerroirContribution/>}
					{record.type === 'AppContributionMapStore' && <EditMapStoreContribution/>}
					{record.type === 'AppContributionProduct' && <EditProductContribution/>}
					{record.type === 'AppContributionProducerStore' && <EditProducerStoreContribution/>}
					{record.user &&
                        <Grid item xs={12}>
                            <UserCardInfo user={record.user}/>
                        </Grid>
					}
				</Grid>
			</Box>
		</ShowBase>
	) : null
}

export default ContributionShow
