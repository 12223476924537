import {BooleanNumField} from '@/components/field'
import * as React from 'react'
import {Fragment, useEffect} from 'react'
import {Datagrid, DateField, FunctionField, List, Pagination, SearchInput, TextField} from 'react-admin'

export default function UserList() {
    return (
        <List
            sort={{field: 'id', order: 'ASC'}}
            perPage={25}
            filters={[<SearchInput name="q" source="q" alwaysOn/>]}
            exporter={false}
        >
            <TableList/>
        </List>
    )
}

const TableList = () => {

    useEffect((): any => {
        document.title = 'Utilisateurs | Clients'
    }, [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <Datagrid
                optimized
                rowClick="edit"
            >
                <TextField source="id" label="Identifiant"/>
                <TextField source="firstName" label="Prénom"/>
                <TextField source="lastName" label="Nom"/>
                <TextField source="email" label="Email"/>
                <FunctionField
                    source="optin_partners"
                    label="Opt-in partenaire"
                    render={(record: any, source: any) =>
                        <BooleanNumField
                            label="Publié"
                            source={source}
                        />
                    }
                />
                <FunctionField
                    source="enabled"
                    label="Actif"
                    render={(record: any, source: any) =>
                        <BooleanNumField
                            label="Publié"
                            source={source}
                        />
                    }
                />
                <DateField source="created_at" label="Date de création"/>
            </Datagrid>
        </Fragment>
    )
}
