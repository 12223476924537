import {Commune} from '@/types'
import * as React from 'react'
import {Fragment, useCallback, useEffect} from 'react'
import {
    Datagrid,
    FunctionField,
    Link,
    List,
    Pagination,
    ReferenceField,
    SearchInput,
    TextField,
    useCreatePath
} from 'react-admin'

export default function CommuneList() {
    return (
        <List
            sort={{field: 'terroir', order: 'ASC'}}
            perPage={25}
            filters={[<SearchInput name="q" source="q" alwaysOn/>]}
            exporter={false}
        >
            <TableList/>
        </List>
    )
}

const TableList = () => {

    const createPath = useCreatePath()

    useEffect((): any => {
        document.title = 'Communes'
    }, [])

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <Datagrid
                optimized
                rowClick="edit"
            >
                <TextField source="id" label="Identifiant"/>
                <FunctionField
                    source="name"
                    label="Libellé"
                    render={(record: Commune) =>
                        <Link
                            to={createPath({resource: 'communes', type: 'edit', id: record.id})}
                            onClick={handleClick}
                            style={{textDecoration: 'none'}}
                        >
                            <TextField source="name" color="primary"/>
                            <TextField source="name_alt" color="black" sx={{
                                display: 'block',
                                fontStyle: 'italic',
                                color: 'black !important',
                                fontSize: '12px'
                            }}/>
                        </Link>
                    }
                />
                <TextField source="zipcode" label="Code postal"/>
                <ReferenceField source="department" reference="departments" label="Département">
                    <TextField source="code_insee"/>
                </ReferenceField>
                <ReferenceField source="terroir" reference="terroirs" label="Terroir"/>
            </Datagrid>
        </Fragment>
    )
}
