import CategoryIcon from '@mui/icons-material/Category'
import {Card, CardContent} from '@mui/material'
import {FilterList, FilterListItem, FilterLiveSearch} from 'react-admin'

const Aside = () => {

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block'
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start'
            }}
        >
            <CardContent sx={{pt: 1}}>
                <FilterLiveSearch
                    label="Recherche"
                    helperText={'Recherche par identifiant'}
                />
                <FilterList
                    label="Type de contribution"
                    icon={<CategoryIcon/>}
                >
                    <FilterListItem
                        label="Nouveaux producteurs"
                        value={{type: 'map_store'}}
                    />
                    <FilterListItem
                        label="Produit"
                        value={{type: 'product'}}
                    />
                    <FilterListItem
                        label="Terroir"
                        value={{type: 'terroir'}}
                    />
                    <FilterListItem
                        label="Établissement"
                        value={{type: 'store'}}
                    />
                </FilterList>
                <FilterList
                    label="Statut"
                    icon={<CategoryIcon/>}
                >
                    <FilterListItem
                        label="Créée"
                        value={{status: 0}}
                    />
                    <FilterListItem
                        label="Traité partiellement"
                        value={{status: 1}}
                    />
                    <FilterListItem
                        label="Traité"
                        value={{status: 2}}
                    />
                </FilterList>

                {/*<FilterList*/}
                {/*    label="Réinitialiser les filtres"*/}
                {/*    icon={<ClearIcon/>}*/}
                {/*    active={true}*/}
                {/*    toggle={false}*/}
                {/*    sx={{...styles.filter, '>div span': {fontSize: '10px'}}}*/}
                {/*    onClick={resetFilters}*/}
                {/*/>*/}
            </CardContent>
        </Card>
    )
}

export default Aside
