import Aside from '@/app-contributions/Aside'
import {getLabelFromType, getStatusClass, getStatusLabel} from '@/app-contributions/index'
import {AppContribution, AppContributionTypes} from '@/types'
import {Chip, Typography} from '@mui/material'
import * as React from 'react'
import {Fragment, useCallback, useEffect} from 'react'
import {Datagrid, DateField, FunctionField, Link, List, Pagination, TextField, useCreatePath} from 'react-admin'

export default function ContributionList() {
	return (
		<List
			sort={{field: 'id', order: 'DESC'}}
			perPage={25}
			exporter={false}
			aside={<Aside/>}
		>
			<TableList/>
		</List>
	)
}

const TableList = () => {

	const createPath = useCreatePath()

	useEffect((): any => {
		document.title = 'Contributions'
	}, [])

	const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => e.stopPropagation(), [])

	return (
		<Fragment>
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]}/>
			<Datagrid
				size="medium"
				sx={{'width': '-webkit-fill-available'}}
				optimized
				rowClick="show"
			>
				<TextField source="id"/>
				<FunctionField
					label="Type"
					render={(record: AppContributionTypes) => <>
						<Typography>{getLabelFromType(record.type)}</Typography>
						{record.type === 'AppContributionProduct' && record.product &&
                            <Link
                                to={createPath({resource: 'products', type: 'edit', id: record.product.id})}
                                onClick={handleClick}
                                style={{textDecoration: 'none'}}
                            >
								{record?.product.name}
                            </Link>
						}
						{record.type === 'AppContributionTerroir' && record.terroir &&
                            <Link
                                to={createPath({resource: 'terroirs', type: 'edit', id: record.terroir.id})}
                                onClick={handleClick}
                                style={{textDecoration: 'none'}}
                            >
								{record?.terroir.name}
                            </Link>
						}
						{record.type === 'AppContributionProducerStore' && record.store &&
                            <Link
                                to={createPath({resource: 'producers/stores', type: 'edit', id: record.store.id})}
                                onClick={handleClick}
                                style={{textDecoration: 'none'}}
                            >
								{record?.store.name}
                            </Link>
						}
						{record.type === 'AppContributionMapStore' &&
                            <Typography variant="body2">
								{record?.name}
                            </Typography>
						}
					</>}
				/>
				<TextField source="user.email" label="Email (si user)"/>
				<FunctionField
					label="Statut"
					render={(record: AppContribution) => <>
						<Chip
							label={getStatusLabel(record.status)}
							color={getStatusClass(record.status)}
							// variant="outlined"
							size="small"
						/>
					</>}
				/>
				{/* <FunctionField
				 source="email"
				 label="Email"
				 render={(record: any) => // @todo type Contribution
				 <Link
				 to={createPath({ resource: 'users', type: 'edit', id: record.user.id })}
				 onClick={handleClick}
				 style={{ textDecoration: 'none' }}
				 >
				 <TextField source="user.email" color="primary" />
				 </Link>
				 }
				 /> */}
				{/*<FunctionField*/}
				{/*    source="terroir.name"*/}
				{/*    label="Terroir"*/}
				{/*    render={(record: any) => // @todo type Contribution*/}
				{/*        <Link*/}
				{/*            to={createPath({resource: 'terroirs', type: 'edit', id: record.terroir.id})}*/}
				{/*            onClick={handleClick}*/}
				{/*            style={{textDecoration: 'none'}}*/}
				{/*        >*/}
				{/*            {record?.terroir.code} -- {record?.terroir.name}*/}
				{/*        </Link>*/}
				{/*    }*/}
				{/*/>*/}
				<DateField source="created_at" label="Créée le"/>
				<DateField source="updated_at" label="Modifiée le"/>
			</Datagrid>
		</Fragment>
	)
}
