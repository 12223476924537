import * as React from 'react';
import { Popover, Slider, IconButton, ToggleButton, Tooltip } from '@mui/material';
import './TrustIndicator.scss';

export const TrustIndicator = ( props: any ) => {

    const { onChange, value } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

	const marks = [
		{
		  value: 0,
		  label: '0',
		},
		{
		  value: 5,
		  label: '5',
		},
		{
		  value: 10,
		  label: '10',
		},
	];

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
			<Tooltip title="Modifier la valeur">
				<IconButton
					onClick={handleClick}
				>
					{value ? value : 0}
				</IconButton>
			</Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >

            <Slider
				min={0}
				max={10}
				defaultValue={value}
				aria-labelledby="discrete-slider-custom"
				step={1}
				onChange={onChange}
				marks={marks}
				valueLabelDisplay="auto"
				style={{ margin: '20px 0' }}
          	/>
            </Popover>
        </div>
  );
}

export default TrustIndicator;
