import UserAdminCreate from '@/users/admins/create'
import UserAdminEdit from '@/users/admins/edit'
import UserList from '@/users/admins/list'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'

export default {
    icon: ManageAccountsOutlinedIcon,
    list: UserList,
    edit: UserAdminEdit,
    create: UserAdminCreate
}
