import {LinearProgressWithLabel} from '@/components/field/LinearProgressField'
import {Box, Grid, Tab, Tabs, Typography} from '@mui/material'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {ShowBase, useEditController} from 'react-admin'
import './style.css'
import UserCardInfo from '@/components/user/userCardInfo'
import TerroirCardInfo from '@/terroirs/components/terroirCardInfo'

const ContributionShow = () => {
	const {record, isLoading: loading} = useEditController()
	const [activeTab, setActiveTab] = useState(0)

	useEffect(() => {
		if (!loading) {
			document.title = record?.id || 'Détails'
		}
	}, [record, loading])

	const returnValue = (val: boolean | number) => {
		if (val == 2 || val == true) {
			return 'Oui'
		} else if (val == 1 || val == false) {
			return 'Non'
		} else if (val == 0) {
			return 'NSP'
		} else {
			return ''
		}
	}

	const columns: GridColDef[] = [
		{field: 'id', headerName: 'ID', flex: 0.15, minWidth: 70},
		{field: 'name', headerName: 'Nom du produit', flex: 1},
		{field: 'category', headerName: 'Catégorie', flex: 0.5},
		{field: 'heritage', headerName: 'Patrimoine gastro. du terroir', flex: 0.5},
		{field: 'is_1932', headerName: '1932', flex: 0.5},
		{field: 'is_1992', headerName: '1992', flex: 0.5},
		{field: 'is_2022', headerName: '2022', flex: 0.5},
		{field: 'is_custom', headerName: 'Produit ajouté ?', flex: 0.5},
		{field: 'is_produced', headerName: 'Produit par l\'user', flex: 0.7}
	]

	const rows = (record?.products ? record?.products : []).map((product: any) => ({
		id: product.id,
		name: product.product?.name ? product.product?.name : product.name,
		category: product.product?.categoryName ?? null,
		heritage: returnValue(product.heritage),
		is_1932: returnValue(product.is_1932),
		is_1992: returnValue(product.is_1992),
		is_2022: returnValue(product.is_2022),
		is_custom: returnValue(product.is_custom),
		is_produced: returnValue(product.is_produced),
		status: product.status
	}))

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue)
	}

	const filteredRows = rows?.filter((row: any) => {
		if (activeTab == 0) {
			return row.status == 0
		} else {
			return row.status !== 0
		}
	})

	const getRowClassName = (params: any) => {
		const row = params.row

		if (row.heritage == 'Non' || row.heritage == 'NSP') {
			return 'valid'
		} else {
			if (row.is_1932 && row.is_1992 && row.is_2022)
				return 'valid'
			return ''
		}
	}

	const getTabLabel = (index: number) => {
		if (index === 0) {
			const count = rows?.filter((row: any) => row.status === 0)?.length
			return `En cours (${count})`
		} else {
			const count = rows?.filter((row: any) => row.status !== 0)?.length
			return `Validés (${count})`
		}
	}

	return record ? (
		<ShowBase>
			<Box p="1em">
				<Grid container rowSpacing={3} columnSpacing={{xs: 3, sm: 3, md: 3}}>
					<Grid item xs={6}>
						<UserCardInfo user={record.user}/>
					</Grid>
					<Grid item xs={6}>
						<TerroirCardInfo terroir={record.terroir}/>
					</Grid>
				</Grid>
				<Box mt="3em"/>
				<Box>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography variant="h6" gutterBottom>
							Détails de la contribution : {record.id}
						</Typography>
						<Box sx={{width: '20%'}}>
							<LinearProgressWithLabel value={record?.progress}/>
						</Box>
					</Box>
					<Box sx={{mb: 2}}>
						<Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
							<Tab label={getTabLabel(0)}/>
							<Tab label={getTabLabel(1)}/>
						</Tabs>
					</Box>
					<div style={{height: 800, width: '100%'}}>
						<DataGrid
							rows={filteredRows}
							columns={columns}
							pageSize={10}
							getRowClassName={getRowClassName}
						/>
					</div>
				</Box>
			</Box>
		</ShowBase>
	) : null
}

export default ContributionShow
