import {ProducerStore, Product, Terroir} from '@/types'

export function getProducerStoreLink(producer: ProducerStore) {
	return `${process.env.REACT_APP_FRONT_URL}/carte/${producer.terroir_slug}/producteurs/${producer.slug}`
}

export function getProductLink(product: Product, terroir: Terroir) {
	return `${process.env.REACT_APP_FRONT_URL}/carte/${terroir.slug}/produits/${product.slug}`
}

export function getTerroirLink(terroir: Terroir) {
	return `${process.env.REACT_APP_FRONT_URL}/carte/${terroir.slug}`
}
