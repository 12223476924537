import frenchMessages from 'ra-language-french'
import {TranslationMessages} from 'react-admin'

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    message: {
        update: 'Elément mis à jour',
        update_mot_de_passe: 'Mot de passe mis à jour'
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur'
        },
        dashboard: {
            monthly_revenue: 'CA à 30 jours',
            month_history: 'Chiffre d\'affaire sur 30 jours',
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            all_reviews: 'Voir tous les commentaires',
            new_customers: 'Nouveaux clients',
            all_customers: 'Voir tous les clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un poster |||| par %{customer_name}, %{nb_items} posters'
            },
            welcome: {
                title: 'LOPT ADMIN',
                subtitle:
                    'Ceci est le back-office de LOPT',
                ra_button: 'Accès au site public',
                demo_button: 'Template utilisé'
            }
        },
        menu: {
            produits: 'Produits',
            terroirs: 'Terroirs',
            communes: 'Communes',
            utilisateurs: 'Utilisateurs',
            producteurs: 'Producteurs',
            medias: 'Médias',
            tags: 'Tags'

        }
    },
    resources: {
        products: {
            name: 'Produit ‎ |||| Produits ',
            fields: {
                id: 'Identifiant',
                name: 'Libellé',
                name_alt: 'Libellé alternatif',
                type: 'Type',
                category: 'Catégorie',
                marque_deposee: 'Marque déposée',
                labels: 'Label(s) de qualité',
                lien: 'Lien web (wiki)',
                created_at: 'Date de création',
                updated_at: 'Date de modification'
            },
            fieldGroups: {
                identity: 'Informations',
                details: 'Détails',
                terroirslies: 'Terroirs liés',
                communesliees: 'Communes liées',
                identitevisuelle: 'Identité visuelle'
            },
            filters: {
                est_publie: 'Est publié ?',
                oui: 'Oui',
                non: 'Non',
                categorie: 'Catégorie',
                type: 'Type',
                produit: 'Produit',
                tags: 'Tags',
                recette: 'Recette traditionnelle',
                undefined: 'Non catégorisé',
                fromage: 'Fromages',
                produits_laitiers: 'Produits laitiers (hors fromages)',
                fruits: 'Fruits',
                legumes: 'Légumes',
                champignons: 'Champignons',
                poissons: 'Poissons',
                coquillages: 'Coquillages, crustacés et mollusques',
                viandes: 'Viandes',
                volailles: 'Volailles',
                gibiers: 'Gibiers',
                charcuteries: 'Chacuteries et abats',
                boulangerie: 'Boulangerie, viennoiseries',
                confiseries: 'Confiseries',
                miels: 'Miels',
                farines: 'Farines, semoules, céréales, et derivés',
                boissons: 'Boissons et spiritueux',
                soupes: 'Soupes et potages',
                entrees: 'Petites entrées, hors-d\'oeuvre chauds ou froids',
                douceurs: 'Douceurs',
                garnitures: 'Garnitures et accompagnements',
                epices: 'Epices, condiments et aromates',
                pates: 'Pâtes',
                sauces: 'Sauces'
            }
        },
        terroirs: {
            name: 'Terroir ‎ |||| Terroirs ',
            fields: {
                id: 'Identifiant',
                libelle: 'Libellé',
                libelle_alternatif: 'Libellé alternatif',
                date_creation: 'Date de création',
                date_modification: 'Date de modification',
                centroid: 'Cordonnées GPS',
                longitude: 'Longitude',
                latitude: 'Latitude'
            },
            fieldGroups: {
                identity: 'Informations',
                details: 'Détails',
                geo: 'Renseignements géographiques',
                produitslies: 'Produits liés',
                communesliees: 'Communes liées',
                terroirsLimitrophes: 'Terroirs Limitrophes',
                identitevisuelle: 'Identité visuelle'
            }
        },
        communes: {
            name: 'Commune ‎ |||| Communes ',
            fields: {
                id: 'Identifiant INSEE',
                libelle: 'Libellé',
                state: 'Département',
                terroir_id: 'Identifiant ZEG',
                code_postal: 'Code postal',
                departement_id: 'Département',
                image: 'Photo'
            },
            fieldGroups: {
                identity: 'Informations',
                details: 'Détails',
                geo: 'Renseignements géographiques',
                products: 'Produits liés',
                communesLimitrophes: 'Communes limitrophes',
                identitevisuelle: 'Identité visuelle'
            }
        },
        utilisateurs: {
            name: 'Utilisateur ‎ |||| Utilisateurs ',
            utilisateurs: 'Utilisateurs',
            pros: 'PROs',
            pros_activites: 'Activités',
            roles: 'Rôles',
            fields: {
                pseudo: 'Pseudo',
                email: 'Email'
            },
            fieldGroups: {
                identity: 'Informations',
                identity_pro: 'Informations de contact',
                details: 'Détails',
                geo: 'Renseignements géographiques',
                entreprise: 'Informations sur l\'entreprise',
                identification: 'Identification',
                avatar: 'Avatar'
            },
            filters: {
                est_valide: 'Est validé ?',
                oui: 'Oui',
                non: 'Non',
                roles: 'Rôles ',
                super_admin: 'Super Admin',
                admin: 'Administrateur',
                redacteur: 'Rédacteur',
                moderateur: 'Modérateur',
                producteur: 'Producteur',
                utilisateur: 'Utilisateur'
            }
        },
        medias: {
            images: 'Image ‎ |||| Images ',
            videos: 'Vidéo ‎ |||| Vidéos ',
            fichiers: 'Fichier ‎ |||| Fichiers ',
            fields: {
                id: 'ID',
                source: 'Chemin source',
                description: 'Titre/Description',
                category: 'Catégorie',
                height: 'Hauteur',
                width: 'Largeur',
                author: 'Nom de l\'auteur',
                author_url: 'Lien du site de l\'auteur',
                license: 'Nom de la licence',
                license_url: 'Lien vers les détails de la licence',
                created_at: 'Date de création',
                updated_at: 'Date de modification'
            },
            fieldGroups: {
                general: 'Informations générales',
                credits: 'Crédits'
            },
            filters: {
                category: 'Categorie',
                undefined: 'Non catégorisé'
            },
            notification: {
                image_upload_success: 'L\'image a été uploadé avec succès !',
                image_upload_error: 'Désolé, l\'image n\'a pas plus être uploadé.',
                image_upload_size_limit: 'L\'image est trop grande. (taille maximale : 5 mo)',
                image_upload_incompatible: 'Ce fichier n\'est pas compatible. (format accepté : JPG, PNG, GIF)'
            }
        },
        tags: {
            name: 'Tag ‎ |||| Tags',
            fields: {
                id: 'ID',
                libelle: 'Libellé',
                categorie: 'Catégorie',
                type: 'Type'
            },
            fieldGroups: {
                identity: 'Informations'
            }
        },
        customers: {
            name: 'Client |||| Clients',
            fields: {
                address: 'Rue',
                birthday: 'Anniversaire',
                city: 'Ville',
                stateAbbr: 'Etat',
                commands: 'Commandes',
                first_name: 'Prénom',
                first_seen: 'Première visite',
                groups: 'Segments',
                has_newsletter: 'Abonné à la newsletter',
                has_ordered: 'A commandé',
                last_name: 'Nom',
                last_seen: 'Vu le',
                last_seen_gte: 'Vu depuis',
                latest_purchase: 'Dernier achat',
                name: 'Nom',
                total_spent: 'Dépenses',
                zipcode: 'Code postal',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe'
            },
            filters: {
                last_visited: 'Dernière visite',
                today: 'Aujourd\'hui',
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment'
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe'
            },
            page: {
                delete: 'Supprimer le client'
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.'
            }
        },
        commands: {
            name: 'Commande |||| Commandes',
            amount: '1 commande |||| %{smart_count} commandes',
            title: 'Commande n°%{reference}',
            fields: {
                basket: {
                    delivery: 'Frais de livraison',
                    reference: 'Référence',
                    quantity: 'Quantité',
                    sum: 'Sous-total',
                    tax_rate: 'TVA',
                    taxes: 'TVA',
                    total: 'Total',
                    unit_price: 'P.U.'
                },
                address: 'Adresse',
                customer_id: 'Client',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                nb_items: 'Nb articles',
                reference: 'Référence',
                returned: 'Annulée',
                status: 'Etat',
                total_gte: 'Montant minimum'
            },
            section: {
                order: 'Commande',
                customer: 'Client',
                shipping_address: 'Adresse de livraison',
                items: 'Articles',
                total: 'Total'
            }
        },
        invoices: {
            name: 'Facture |||| Factures',
            fields: {
                id: 'Numéro',
                date: 'Date de facture',
                customer_id: 'Client',
                command_id: 'Commande',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                address: 'Adresse',
                total_ex_taxes: 'Montant HT',
                delivery_fees: 'Frais de livraison',
                taxes: 'TVA'
            }
        },
        // products: {
        //     name: 'Poster |||| Posters',
        //     fields: {
        //         category_id: 'Catégorie',
        //         height_gte: 'Hauteur mini',
        //         height_lte: 'Hauteur maxi',
        //         height: 'Hauteur',
        //         image: 'Photo',
        //         price: 'Prix',
        //         reference: 'Référence',
        //         sales: 'Ventes',
        //         stock_lte: 'Stock faible',
        //         stock: 'Stock',
        //         thumbnail: 'Aperçu',
        //         width_gte: 'Largeur mini',
        //         width_lte: 'Margeur maxi',
        //         width: 'Largeur',
        //     },
        //     tabs: {
        //         image: 'Image',
        //         details: 'Détails',
        //         description: 'Description',
        //         reviews: 'Commentaires',
        //     },
        //     filters: {
        //         categories: 'Catégories',
        //         stock: 'Stock',
        //         no_stock: 'En rupture',
        //         low_stock: '1 - 9 unités',
        //         average_stock: '10 - 49 unités',
        //         enough_stock: '50 unités et plus',
        //         sales: 'Ventes',
        //         best_sellers: 'Meilleures ventes',
        //         average_sellers: 'Moyennes',
        //         low_sellers: 'Peu vendu',
        //         never_sold: 'Jamais vendu',
        //     },
        // },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                name: 'Nom',
                products: 'Produits'
            }
        },
        reviews: {
            name: 'Commentaire |||| Commentaires',
            amount: '1 commentaire |||| %{smart_count} commentaires',
            relative_to_poster: 'Commentaire sur',
            detail: 'Détail du commentaire',
            fields: {
                customer_id: 'Client',
                command_id: 'Commande',
                product_id: 'Produit',
                date_gte: 'Publié depuis',
                date_lte: 'Publié avant',
                date: 'Date',
                comment: 'Texte',
                status: 'Statut',
                rating: 'Classement'
            },
            action: {
                accept: 'Accepter',
                reject: 'Rejeter'
            },
            notification: {
                approved_success: 'Commentaire approuvé',
                approved_error: 'Erreur: Commentaire non approuvé',
                rejected_success: 'Commentaire rejeté',
                rejected_error: 'Erreur: Commentaire non rejeté'
            }
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Clients',
                name: 'Nom'
            },
            data: {
                compulsive: 'Compulsif',
                collector: 'Collectionneur',
                ordered_once: 'A commandé',
                regular: 'Régulier',
                returns: 'A renvoyé',
                reviewer: 'Commentateur'
            }
        },
        login: {
            invalidInput: 'Email ou mot de passe invalide.'
        }
    }
}

export default customFrenchMessages
