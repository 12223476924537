import DatagridUser from '@/users/components/listFields'
import * as React from 'react'
import {Fragment, useEffect} from 'react'
import {List, Pagination, SearchInput} from 'react-admin'

export default function UserList() {
    return (
        <List
            sort={{field: 'id', order: 'ASC'}}
            perPage={25}
            filters={[<SearchInput name="q" source="q" alwaysOn/>]}
            exporter={false}
        >
            <TableList/>
        </List>
    )
}

const TableList = () => {

    useEffect((): any => {
        document.title = 'Utilisateurs | Admins'
    }, [])

    return (
        <Fragment>
            <Pagination rowsPerPageOptions={[5, 10, 25]}/>
            <DatagridUser
                optimized
                rowClick="edit"
            />
        </Fragment>
    )
}
