import fetchApi from '@/lib/tools/api'
import {User} from '@/types'

import PersonIcon from '@mui/icons-material/Person'
import {Box, Chip, Divider, Typography} from '@mui/material'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import React, {useEffect, useState} from 'react'
import {useGetList, useTranslate} from 'react-admin'
import {Link} from 'react-router-dom'
import CardWithIcon from './CardWithIcon'

const TotalUsers = () => {
    const translate = useTranslate()
    const [statsUsers, setStatsUsers] = useState<any>([])
    const [datetime, setDatetime] = useState<dayjs.Dayjs | null>(dayjs('05-16-2023'))
    const [loading, setLoading] = useState(false)

    const PARAMS = {
        context: 'users',
        endpoints: {
            search: () => `/users/clients/stats`
        }
    }

    const style = {
        box: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '4px',
            transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
                backgroundColor: '#d8ddc178'
            },
            '&> a': {
                width: '-webkit-fill-available;'
            }
        },
        card: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '-webkit-fill-available',
            padding: '0 6px'
        },
        chip: {
            backgroundColor: '#3a4e38',
            margin: 0,
            color: 'white'
        },
        divider: {
            borderColor: '#000'
        }
    }

    interface statsProps {
        total: number;
        total_clients: number;
        total_producers: number;
    }

    const {total} = useGetList<User>('users')

    useEffect(() => {
        const getStatsUsers = async () => {
            setLoading(true)

            await fetchApi<statsProps>(
                PARAMS.endpoints.search(),
                undefined,
                {
                    stats: 'true',
                    createdAt: dayjs(datetime).format('YYYY/MM/DD')
                },
                'GET'
            )
                .then((res: statsProps) => {
                    setStatsUsers({
                        total: res.total_clients + res.total_producers,
                        total_clients: res.total_clients,
                        total_producers: res.total_producers
                    })
                })

            setLoading(false)
        }

        getStatsUsers()

    }, [datetime])

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        const formattedDate = date ? dayjs(date) : null
        setDatetime(formattedDate)
    }

    return (
        <CardWithIcon
            icon={PersonIcon}
            title={'Utilisateurs'}
            subtitle={total}
        >
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{alignSelf: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="A partir du :" value={datetime} onChange={handleDateChange}/>
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>

                <Divider sx={style.divider}/>

                <Box sx={style.box}>
                    <Box sx={style.card}>
                        <Typography variant="overline" display="block" gutterBottom>
                            Nouveaux utilisateurs:
                        </Typography>

                        <Chip label={statsUsers.total} sx={style.chip}/>
                    </Box>
                </Box>

                <Divider sx={style.divider}/>

                <Box sx={style.box}>
                    <Link to="/producers/users">
                        <Box sx={style.card}>
                            <Typography variant="overline" display="block" gutterBottom>
                                Pro:
                            </Typography>

                            <Chip label={statsUsers.total_producers} sx={style.chip}/>
                        </Box>
                    </Link>
                </Box>

                <Divider sx={style.divider}/>

                <Box sx={style.box}>
                    <Link to="/users/clients">
                        <Box sx={style.card}>
                            <Typography variant="overline" display="block" gutterBottom>
                                Non pro:
                            </Typography>

                            <Chip label={statsUsers.total_clients} sx={style.chip}/>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </CardWithIcon>
    )
}

export default TotalUsers
