import FilterCommune from '@/components/filter/filterCommune'
import {Card, CardContent} from '@mui/material'
import * as React from 'react'
import {useState} from 'react'

const BASE_FILTER_STATE = {
    commune: true
}

type PropsViewFilters = typeof BASE_FILTER_STATE

export default function ListFilters() {

    const [viewFilters, setViewFilters] = useState<PropsViewFilters>(BASE_FILTER_STATE)

    return (
        <Card sx={{order: -1, mr: 2, mt: 8, width: 200}}>
            <CardContent>
                {/*<SavedQueriesList/>*/}
                {/*<FilterLiveSearch/>*/}
                {/*<FilterList label="Subscribed to newsletter" icon={null}>*/}
                {/*    <FilterListItem label="Yes" value={{has_newsletter: true}}/>*/}
                {/*    <FilterListItem label="No" value={{has_newsletter: false}}/>*/}
                {/*</FilterList>*/}
                {/*<FilterList label="Category" icon={<CategoryIcon/>}>*/}
                {/*    <FilterListItem label="Tests" value={{category: 'tests'}}/>*/}
                {/*    <FilterListItem label="News" value={{category: 'news'}}/>*/}
                {/*    <FilterListItem label="Deals" value={{category: 'deals'}}/>*/}
                {/*    <FilterListItem label="Tutorials" value={{category: 'tutorials'}}/>*/}
                {/*</FilterList>*/}
                <FilterCommune
                    active={viewFilters.commune}
                    onClick={() =>
                        setViewFilters({
                            ...viewFilters,
                            commune: !viewFilters.commune
                        })
                    }
                />
            </CardContent>
        </Card>
    )
}
//
