import {Box, Typography} from '@mui/material'
import {BooleanInput, TextInput} from 'react-admin'
import React from 'react'

export default function FieldsPublication() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Présence sur le site</Typography>
            <Box sx={{display: 'flex', gap: '20px'}}>
                <TextInput
                    disabled
                    source="id"
                    name="id"
                    id="identifiant"
                    required
                    fullWidth
                />
            </Box>
        </Box>
    )
}
