import {Box, Typography} from '@mui/material'
import {BooleanInput, TextInput} from 'react-admin'
import React from 'react'

export default function FieldsInfos() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Informations complémentaires</Typography>
            <TextInput
                label="SIRET"
                name="siret"
                source="siret"
                fullWidth
            />
        </Box>
    )
}
