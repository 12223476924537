import {Box, Typography} from '@mui/material'
import React from 'react'
import {AutocompleteInput, ReferenceInput, required, SelectInput, TextInput, useResourceContext} from 'react-admin'

export default function FieldsIdentity() {

    const resource = useResourceContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>
                Informations de contact
            </Typography>
            <SelectInput
                name="civility"
                source="civility"
                optionValue="id"
                label="Civilité"
                choices={[
                    {id: 'Madame', name: 'Madame'},
                    {id: 'Monsieur', name: 'Monsieur'},
                    {id: 'Neutre', name: 'Neutre'}
                ]}
                fullWidth
                required
            />
            <TextInput
                // variant={'outlined'}
                name="firstName"
                source="firstName"
                label="Prénom"
                fullWidth
                validate={[required()]}
            />
            <TextInput
                name="lastName"
                source="lastName"
                label="Nom"
                fullWidth
                validate={[required()]}
            />
            <TextInput
                name="email"
                source="email"
                fullWidth
                validate={[required()]}
            />
            {resource === 'producers/users' &&
                <>
                    <TextInput
                        name="phone"
                        source="phone"
                        label="Téléphone"
                        fullWidth
                    />
                    <TextInput
                        name="phone_secondary"
                        source="phone_secondary"
                        label="Téléphone secondaire"
                        fullWidth
                    />
                </>
            }
            {resource !== 'users/admins' &&
                <ReferenceInput
                    name="commune"
                    source="commune"
                    reference="communes"
                >
                    <AutocompleteInput
                        label="Commune"
                        name="commune"
                        optionText={option => `${option.zipcode} -- ${option.name}`}
                        optionValue="id"
                        debounce={100}
                    />
                </ReferenceInput>
            }
        </Box>
    )
}
