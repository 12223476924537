import {Box, Typography} from '@mui/material'
import {AutocompleteInput, ReferenceInput, required, TextInput, useRecordContext} from 'react-admin'
import React from 'react'
import MapField from '@/components/field/mapField'
import {ProducerStore} from '@/types'

export default function FieldsLocation({displayMap = false}) {

    const record = useRecordContext<ProducerStore>()

    return (
        <Box
            paddingTop={3}
            display={'flex'}
            flexDirection={'column'}
        >
            <Typography variant="h6" gutterBottom>Localisation</Typography>
            <MapField
                source="coordinates"
                coordinates={record?.coordinates}
            />
            <TextInput
                label="Adresse"
                name="address"
                source="address"
                fullWidth
                validate={[required()]}
            />
            <TextInput
                label="Adresse secondaire"
                name="address_secondary"
                source="address_secondary"
                fullWidth
            />
            <ReferenceInput
                name="commune"
                source="commune"
                reference="communes"
            >
                <AutocompleteInput
                    label="Commune"
                    name="commune"
                    optionText={option => `${option.zipcode} -- ${option.name}`}
                    optionValue="id"
                    debounce={100}
                />
            </ReferenceInput>
            <ReferenceInput
                name="terroir"
                source="terroir"
                reference="terroirs"
            >
                <AutocompleteInput
                    disabled
                    label="Terroir"
                    source="name"
                    name="terroir"
                    optionText="name"
                    optionValue="id"
                    fullWidth
                />
            </ReferenceInput>
        </Box>
    )
}
