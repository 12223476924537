import {Box, Typography} from '@mui/material'
import React from 'react'
import {number, TextInput} from 'react-admin'

export default function FieldsInfos() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Informations complémentaires</Typography>
            <TextInput
                label="SIREN"
                name="siren"
                source="siren"
                fullWidth
            />
            <TextInput
                label="Code APE"
                name="code_ape"
                source="code_ape"
                fullWidth
            />
            <TextInput
                label="Code emballeur"
                name="code_packer"
                source="code_packer"
                fullWidth
            />
            <TextInput
                label="Identifiant MGC (ITL)"
                name="client_id"
                source="client_id"
                fullWidth
                validate={[number()]}
            />
        </Box>
    )
}
