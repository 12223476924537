import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

const URLField = ( props: any ) =>
{
    const { target, source, alt } = props;
    const record = useRecordContext(props);

    return (
        <>
            <Link href={`${String(process.env.REACT_APP_URL)}/#/${target}/${record.id}`}>
                {record[source]}
            </Link>
            {alt &&
                <Typography sx={{ fontStyle: 'italic', fontWeight: 'normal', fontSize: '12px'}} variant="subtitle2" gutterBottom>
                    {record[alt]}
                </Typography>
            }
        </>
    );
};

export default URLField;
