import { ChangeEvent , ReactEventHandler, useEffect } from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    AutocompleteArrayInput,
    ReferenceArrayInput,
    useListFilterContext,
    SelectInput,
    required,
    Form,
} from 'react-admin';
import { styles, transitions } from '@/products/Aside'
import { Box, BoxProps, Typography } from '@mui/material';

interface propsTagsFilter extends BoxProps {
    active: boolean;
}

const FilterTags = (props: propsTagsFilter) => {

    const { active, onClick } = props
    const PARAMS = {
        label: 'Tags', 
        icon: <LocalOfferIcon />,
        operator: 'AND'
    }

    const { filterValues, setFilters } = useListFilterContext();

    const handleTagsChange = (event: React.ChangeEvent) => {
        setFilters({ ...filterValues, tags: event, operator: filterValues?.operator ?? PARAMS.operator }, true);
    };

    const handleOperatorChange = (event: any) => {
        setFilters({ ...filterValues, operator: event.target.value }, true);
    };

    useEffect(() => {
        return () => {
            setFilters({ tags: [], operator: PARAMS.operator }, true)
        };
    }, []);
  
    return (
        <Form>
            <Box sx={styles.filter}>
                <Box mt={2} display="flex" alignItems="center" onClick={onClick}>
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>{PARAMS.icon}</Box>
                        <Typography variant="overline">
                            {PARAMS.label}
                        </Typography>
                    </Box>
                    { active ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                </Box>
            {
                <Box sx={{...styles.buttons, ...transitions(active, '0.4s')}}>
                    <ReferenceArrayInput
                        name="tags"
                        label="Tags"
                        reference="tags"
                        source="tags"
                    >
                        <>
                            <AutocompleteArrayInput
                                blurOnSelect={false}
                                name="tags"
                                optionText="name"
                                multiple={true}
                                optionValue="id"
                                debounce={250}
                                onChange={handleTagsChange}
                                size='medium'
                                defaultValue={filterValues?.tags}
                            />
                    
                            <SelectInput
                                source="operator"
                                className="selectInput"
                                label="Opérateur"
                                optionValue="name"
                                choices={[
                                    { id: true, name: 'OR'},
                                    { id: false, name: 'AND'}
                                ]}  
                                defaultValue={PARAMS.operator}
                                validate={required()}
                                onChange={handleOperatorChange}
                            />
                        </>
                    </ReferenceArrayInput>
                </Box>
            }
            </Box>
        </Form>
   );
};

export default FilterTags;


