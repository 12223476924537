import React, { useState, useEffect } from "react"
import {
    Autocomplete,
    Box, 
    Button,
    ToggleButton,
    Select,
    MenuItem, 
    TextField, 
    IconButton,
    Paper, 
    InputBase,
    ImageList, 
    ImageListItem, 
    ImageListItemBar,
    useTheme, 
    useMediaQuery,
    FormControl,
    Input,
    InputLabel,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'

import './ModalViewCode.scss';

import Modal from 'react-bootstrap/Modal';
import { HiCode } from "react-icons/hi";

const ModalViewCode = () => {

    // Modal affiché par défaut
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("Code source")

    const [description, setDescription] = useState<any>("")

    // Evènements ouvrir/fermer la modal
    const handleShow = () => {
        setShow(true);
        setLoading(true);
    };

    const handleClose = () => {
        setShow(false)
    };

    const handleSubmit = () => {
        let desc = document.querySelector("#description > p") as HTMLInputElement
        desc.innerHTML = description

        handleClose()
    }

    useEffect(() => {
        if (show) {
            let desc = document.querySelector("#description") as HTMLInputElement;
            desc.innerHTML.includes("<br class") ? setDescription("") : setDescription(desc.innerHTML)
        }
    }, [show])

    return (
        <>
            <ToggleButton
                aria-label="Code source"
                title="Code source"
                value={description}
                onClick={handleShow}
                sx={{ mR: 8, mB: 8, p: "15px"}}
            >
                <HiCode fontSize="inherit" />
            </ToggleButton>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-code"
                backdrop="static"
                //aria-labelledby="example-custom-modal-styling-title"
            >
 
                <Modal.Header closeButton>
                    <h6 className="mt-auto mb-auto"> {title} </h6>
                </Modal.Header>

                <Modal.Body>

                    <TextField
                        multiline
                        rows={8}
                        value={description}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)}
                        fullWidth
                    />

                    <Box mt="1em" />

                    <Box sx={{display: 'flex', justifyContent: 'end', gap: '10px'}}>
                        <Button onClick={handleClose} > Annuler </Button>
                        <Button onClick={handleSubmit} variant="contained" > Mettre à jour </Button>
                    </Box>

                </Modal.Body>
                
            </Modal>
        </>
    )
}

export default ModalViewCode;