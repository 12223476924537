import {useEffect} from 'react'
import {
    Create,
    TextInput,
    SimpleForm,
    useTranslate,
    AutocompleteInput,
    SaveButton,
    DeleteButton,
    Toolbar,
    useNotify,
    required, ReferenceInput, AutocompleteArrayInput, ReferenceArrayInput, useRedirect
} from 'react-admin'
import { Grid, Box, Typography } from '@mui/material'

import FullNameField from '@/components/field/FullNameField'
import PreviewImage from '@/layout/PreviewImage'
import React from 'react'
import DefaultEditor from '@/components/form/DefaultEditor'
import BeforeSave from '@/layout/BeforeSave'

const CommuneEdit = () => {

    const translate = useTranslate()

    useEffect((): any => {
        document.title = 'Nouvelle commune'
    }, [])

    return (
        <Create title={<CommuneTitle />}>
            <SimpleForm
                warnWhenUnsavedChanges
                noValidate
                toolbar={ <MyToolbar />}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Grid container display={{ xs: 'block', sm: 'flex'}} sx={{gap: "30px", justifyContent: "space-between"}}>
                    <Grid item sm={4} md={4}>
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.communes.fieldGroups.identity'
                            )}
                        </Typography>
                        <TextInput
                            disabled
                            id="identifiant"
                            source="id"
                            fullWidth
                            label="Identifiant"
                        />
                        <TextInput
                            label="Nom"
                            name="name"
                            source="name"
                            validate={req}
                            fullWidth
                        />
                        <TextInput
                            label="Nom normalizé"
                            name="name_normalize"
                            source="name_normalize"
                            validate={req}
                            fullWidth
                        />
                        <TextInput
                            source="article"
                            fullWidth
                        />
                        <TextInput
                            type="text"
                            source="code_insee"
                            validate={req}
                            fullWidth
                        />
                        <TextInput
                            type="text"
                            source="zipcode"
                            validate={req}
                            fullWidth
                        />
                        <ReferenceInput
                            name="terroir"
                            label="Terroirs"
                            reference="terroirs"
                            source="terroir"
                        >
                            <AutocompleteInput
                                name="terroir"
                                optionText="name"
                                optionValue="id"
                                debounce={100}
                            />
                        </ReferenceInput>

                        <ReferenceInput
                            name="department"
                            label="Département"
                            reference="departments"
                            source="department"
                        >
                            <AutocompleteInput
                                name="department"
                                optionText="name"
                                optionValue="id"
                                debounce={100}
                            />
                        </ReferenceInput>
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.communes.fieldGroups.geo'
                            )}
                        </Typography>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    source="lng"
                                    fullWidth
                                    helperText={false}
                                    validate={req}
                                />
                            </Box>
                            <Box flex={2}>
                                <TextInput
                                    source="lat"
                                    fullWidth
                                    helperText={false}
                                    validate={req}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={6} md={6}>
                        <Grid>
                            <Typography variant="h6" gutterBottom>Identité visuelle</Typography>
                            <BeforeSave/>
                        </Grid>
                        <Box mt="4em" />
                        <Typography variant="h6" gutterBottom>Produits liés</Typography>
                        <BeforeSave/>
                        <Box mt="4em" />
                        <Typography variant="h6" gutterBottom>Communes limitrophes</Typography>
                        <ReferenceArrayInput
                            name="neighbors"
                            reference="communes"
                            source="neighbors"
                        >
                            <AutocompleteArrayInput
                                label="Communes"
                                name="neighbors"
                                optionText="name"
                                multiple={true}
                                optionValue="id"
                                debounce={250}
                            />
                        </ReferenceArrayInput>
                    </Grid>
                </Grid>
                <Box mt="3em" />
                <DefaultEditor/>
            </SimpleForm>
        </Create>
    )
}

const req = [required()]

const CommuneTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />

const MyToolbar = () => {

    const notify = useNotify()
    const redirect = useRedirect()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton
                    className="save"
                    label="Enregistrer et quitter"
                    mutationOptions={{
                        onSuccess: event => {
                            // @todo redirect liste
                            notify('ra.notification.created', {
                                type: 'info',
                                messageArgs: { smart_count: 1 },
                            })
                            redirect('/products', 'products')
                        }
                    }}
                />
                <SaveButton
                    className="save"
                    label="Enregistrer"
                    type="button"
                    variant="text"
                />
            </Box>
        </Toolbar>
    )
}
export default CommuneEdit
