import {Box, Typography} from '@mui/material'
import {BooleanInput} from 'react-admin'
import React from 'react'

export default function FieldsSell() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h6" gutterBottom>Informations de vente</Typography>
            <BooleanInput
                label="Bio"
                name="is_organic"
                source="is_organic"
            />
            <BooleanInput
                label="Vente en direct"
                name="is_direct_selling"
                source="is_direct_selling"
            />
        </Box>
    )
}
