import FieldsIdentity from '@/users/components/fields/identity'
import FieldsMarketing from '@/users/components/fields/marketing'
import FieldsRoles from '@/users/components/fields/roles'
import FieldsSecurity from '@/users/components/fields/security'
import FieldsStatus from '@/users/components/fields/status'
import {Box, Grid} from '@mui/material'
import React, {useEffect} from 'react'
import {DeleteButton, Edit, required, SaveButton, SimpleForm, Toolbar, useEditController, useNotify} from 'react-admin'

export default function UserAdminEdit() {

    const {record} = useEditController()

    useEffect(() => {
        if (record) {
            document.title = 'Utilisateur'
        }
    }, [record])

    return (
        <Edit
            mutationMode="pessimistic"
            title={'Utilisateur'}
        >
            <SimpleForm
                record={record}
                warnWhenUnsavedChanges
                noValidate
                toolbar={<FormToolbar/>}
                sx={{
                    padding: {
                        md: '32px',
                        xs: '16px'
                    }
                }}
            >
                <Grid
                    container
                    spacing={5}
                >
                    <Grid item xs={12} md={6} display={'flex'} flexDirection={'column'} gap={3}>
                        <FieldsStatus/>
                        <FieldsIdentity/>
                        <FieldsMarketing/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldsSecurity/>
                        <FieldsRoles/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

const req = [required()]

const FormToolbar = () => {

    const notify = useNotify()

    return (
        <Toolbar className="fixed-bottom" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 5}}>
                <SaveButton alwaysEnable className="save" label="Enregistrer et quitter"/>
                <SaveButton alwaysEnable className="save" label="Enregistrer" type="button" variant="text"
                            mutationOptions={{
                                onSuccess: (data) => {
                                    notify('ra.notification.updated', {
                                        type: 'info',
                                        messageArgs: {smart_count: 1}
                                    })
                                }
                            }}/>
            </Box>
            <DeleteButton/>
        </Toolbar>
    )
}
